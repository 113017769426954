export default {
  defaultMessageStyle: {
    background: '#dfe3e0'
  },
  errorMessageStyle: {
    background: '#f8e6e7'
  },
  infoMessageStyle: {
    background: '#caedff'
  },
  messageStyle: {
    borderRadius: '5px',
    padding: '16px',
    width: '100%'
  }
};
