import { useCallback, useEffect, useState } from 'react';

import { parseXMLFile } from './cardParser';
import { Card } from './cardUtils';

interface UseXMLParserInterface {
  onSuccess: (value: Card[]) => void;
}

type UseXMLParserReturnInterface = [
  {
    isXMLParsing: boolean;
    XMLParsingError: string | null;
  },
  (value: string | null) => void
];

export const useCardsXMLParser = ({ onSuccess }: UseXMLParserInterface): UseXMLParserReturnInterface => {
  const [XML, setXML] = useState<string | null>(null);
  const [isXMLParsing, setIsXMLParsing] = useState<boolean>(false);
  const [XMLParsingError, setXMLParsingError] = useState<string | null>(null);
  const setXMLCallback = useCallback((xml: string | null) => {
    setXML(xml);
  }, []);

  useEffect(() => {
    if (XML === null) {
      return;
    }

    setXMLParsingError(null);
    setIsXMLParsing(true);

    try {
      const parsedXML = parseXMLFile(XML);

      onSuccess(parsedXML);
    } catch (e) {
      setXMLParsingError(e);
    } finally {
      setIsXMLParsing(false);
    }
  }, [XML, onSuccess]);

  return [
    {
      isXMLParsing,
      XMLParsingError
    },
    setXMLCallback
  ];
};
