import { fade, Theme, createStyles } from '@material-ui/core/styles';

export default ({ palette, spacing, shape }: Theme) =>
  createStyles({
    icon: {
      color: palette.text.secondary,
      marginRight: spacing(1)
    },
    input: {
      flexGrow: 1,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '-0.05px'
    },
    root: {
      alignItems: 'center',
      display: 'flex'
    },
    search: {
      position: 'relative',
      borderRadius: shape.borderRadius,
      backgroundColor: fade(palette.common.black, 0.15),
      '&:hover': {
        backgroundColor: fade(palette.common.black, 0.25)
      },
      width: '100%'
    },
    searchIcon: {
      padding: spacing(0, 1),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    inputRoot: {
      color: 'inherit'
    },
    inputInput: {
      padding: spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${spacing(3)}px)`,
      width: '100%'
    }
  });
