import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const useStyles = makeStyles({
  overlay: {
    alignItems: 'center',
    background: 'rgba(0, 0, 0, .5)',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  }
});

export function Overlay(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.overlay}>
      <CircularProgress color="secondary" disableShrink size={80} thickness={3} variant="indeterminate" />
    </div>
  );
}
