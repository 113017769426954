import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';

import { convertCardsToXML } from '../../services/cardParser';
import downloadFile from '../../services/dowloadFile';
import SearchInput from '../SearchInput';
import CardsListItem from './CardsListItem';
import styles from './styles';
import { Card, CardLevel } from "../../services/cardUtils";

const useStyles = makeStyles(styles);

interface CardsListDrawerProps {
  cards: Card[];
  onCardSelect: (card: Card, maxed:boolean ) => void
}

function CardsListDrawer({ cards, onCardSelect }: CardsListDrawerProps): JSX.Element {
  const [searchCardName, setSearchCardName] = useState('');
  const [cardsIds, setFilteredCards] = useState(new Set());
  const filteredCards = cards.filter((card) => cardsIds.has(card.id));
  const classes = useStyles();

  useEffect(() => {
    setSearchCardName('');
  }, [cards]);

  const onSearchCardNameChange = useCallback((e) => {
    setSearchCardName(e.target.value);
  }, []);

  useEffect(() => {
    setFilteredCards(
      cards.reduce((ids: Set<number>, card: Card) => {
        if (card.name.toLowerCase().indexOf(searchCardName.toLowerCase()) > -1) {
          ids.add(card.id);
        }

        return ids;
      }, new Set<number>())
    );
  }, [cards, searchCardName]);

  const exportAllCards = useCallback(() => {
    const data = convertCardsToXML(cards); // `${cards.map(card => convertCardToXML(card.levels['1'])).join('\n')}`;

    downloadFile(data, 'cards_section.xml', 'application/xml');
  }, [cards]);

  return (
    <nav className={classes.listWrapper}>
      <div className={classes.listSubheader}>
        <SearchInput value={searchCardName} onChange={onSearchCardNameChange} />
      </div>
      <div className={classes.filteredAmount}>
        <div>{`Showing ${filteredCards.length} of ${cards.length}`}</div>
        {/*
        <div>
          <button type="button" onClick={exportAllCards}>
            Export all
          </button>
        </div>
        */}
      </div>
      <div className={classes.cardsList}>
        {filteredCards.length > 0 &&
          filteredCards.map((card: Card) => (
            <CardsListItem
              card={card.levels[`${Object.keys(card.levels).length}` as unknown as CardLevel]}
              id={card.id}
              key={`${card.id}-${card.level}`}
              name={card.name}
              onListItemClick={onCardSelect}
            />
          ))}
      </div>
    </nav>
  );
}

export default React.memo(CardsListDrawer);
