import { useEffect, useCallback, useState } from 'react';
import { CARD_SECTIONS, XML_URL, XML_URL_DEV } from '../constants';

interface UseXMLSectionLoaderInterface {
  onSuccess: (xml: string) => void;
}

type UseXMLSectionLoaderReturnInterface = [
  {
    isXMLSectionLoading: boolean;
    XMLSectionLoadingError: any;
  },
  (sectionNumber: number, useDevServer: boolean) => void
];

const loadXMLSectionFile = (section: number, isDev: boolean) => {
  if (section < 1 || section > CARD_SECTIONS) {
    return;
  }

  return fetch(`${isDev ? XML_URL_DEV : XML_URL}cards_section_${section}.xml`)
    .then((response) => response.text());
}

export const useXMLSectionLoader = ({ onSuccess }: UseXMLSectionLoaderInterface): UseXMLSectionLoaderReturnInterface => {
  const [XMLSection, setXMLSection] = useState<{ sectionNumber: number, useDevServer: boolean } | null>(null);
  const [isXMLSectionLoading, setIsXMLSectionLoading] = useState<boolean>(false);
  const [XMLSectionLoadingError, setXMLSectionLoadingError] = useState<string | null>(null);
  const setXMLSectionCallback = useCallback((sectionNumber: number, useDevServer: boolean) => {
    setXMLSection({ sectionNumber, useDevServer });
  }, []);

  useEffect(() => {
    let mounted = true;

    const processXMLSection = async () => {
      if (XMLSection === null) {
        return;
      }

      setXMLSectionLoadingError(null);
      setIsXMLSectionLoading(true);

      try {
        const loadedXMLSection = await loadXMLSectionFile(XMLSection.sectionNumber, XMLSection.useDevServer);

        if (mounted && loadedXMLSection !== undefined) {
          onSuccess(loadedXMLSection);
        }
      } catch (e) {
        if (mounted) {
          setXMLSectionLoadingError(e);
        }
      } finally {
        if (mounted) {
          setIsXMLSectionLoading(false);
        }
      }
    }

    processXMLSection();

    return () => {
      mounted = false;
    }
  }, [XMLSection, onSuccess]);

  return [
    {
      isXMLSectionLoading,
      XMLSectionLoadingError
    },
    setXMLSectionCallback
  ];
}
