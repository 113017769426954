// import cardConfig from './card-config.json';
import parser from 'xml-js';

export type FusionLevel = 0 | 1 | 2;
// const cardTypes = ['commander', 'structure', 'action', 'dominion', 'dominionshard', 'assault', 'Longshot'] as const;
//
// type CardType = typeof cardTypes[number];


/*
function getCardTextView(card) {
  let str = '';
  const type = getCardType(card['id']);

  str += tag('**' + card['name'] + '**', 'b');
  str += eol;
  str += tag('_' + factions[card['type']] + ' ' + rarityNames[card['rarity']] + ' ' + typeNames[type] + '_', 'i');
  str += eol;
  str += type === 'assault' ? card['attack'] : '-';
  str += '/';
  str += card['health'];
  str += '/';
  str += type !== 'action' && type !== 'commander' ? card['cost'] : '-';
  str += eol;

  for (const skill of card['skills']) {
    str += getSkillLine(s, skill) + eol;
  }

  return str;
}
*/

const SkillsOrder = [
  'Trigger:On Play',

  // passive defensive skills
  'Evade',
  'Payback',
  'Revenge',
  'Tribute',
  'Absorb',
  'Wall',
  'Armor',
  'Stasis',
  'Avenge',
  'Scavenge',

  'Trigger:On Attacked',
  // On attacked triggered skills
  'Counter',
  'Corrosive',

  // On activation skills
  'Subdue',
  'Valor',
  'Bravery',
  'Allegiance',
  'Summon',
  'Barrier',
  // active defensive skills
  'Evolve',
  'Enhance',
  'Overload',
  'Rush',
  'Mend',
  'Fortify',
  'Heal',
  'Protect',
  'Entrap',
  'Rally',
  'Enrage',
  // active offensive skills
  'Enfeeble',
  'Strike',
  'Jam',
  'Weaken',
  'Sunder',
  'Siege',
  'Mortar',
  'Mimic',
  'Legion',
  'Coalition',
  'Pierce',
  'Swipe',
  'Inhibit',
  'Sabotage',
  'Disease',
  'Poison',
  'Venom',
  'Leech',
  'Refresh',
  'Berserk',
  'Mark',
  'Flurry',
  'Trigger:On Death'
];

export const cardCategory = {
  normal: 'normal',
  special: 'special',
  fortress_defense: 'fortress_defense',
  fortress_siege: 'fortress_siege',
  fortress_conquest: 'fortress_conquest',
  dominion_alpha: 'dominion_alpha',
  dominion_material: 'dominion_material'
} as const;

export type CardCategory = keyof typeof cardCategory;

const cardSkillTypes = {
  // Placeholder for no-skill:
  // no_skill: '<Error>',

  // Activation (harmful):
  enfeeble: 'Enfeeble',
  jam: 'Jam',
  besiege: 'Mortar',
  // mortar: 'Mortar',
  siege: 'Siege',
  strike: 'Strike',
  sunder: 'Sunder',
  weaken: 'Weaken',

  // Activation (helpful):
  evolve: 'Evolve',
  heal: 'Heal',
  mend: 'Mend',
  overload: 'Overload',
  protect: 'Protect',
  rally: 'Rally',
  fortify: 'Fortify',
  enrage: 'Enrage',
  entrap: 'Entrap',
  rush: 'Rush',

  // Activation (unclassified/polymorphic):
  mimic: 'Mimic',

  // Defensive:
  // armor: 'Armor',
  armored: 'Armor',
  avenge: 'Avenge',
  scavenge: 'Scavenge',
  corrosive: 'Corrosive',
  counter: 'Counter',
  evade: 'Evade',
  subdue: 'Subdue',
  absorb: 'Absorb',
  flying: 'Flying',
  payback: 'Payback',
  revenge: 'Revenge',
  tribute: 'Tribute',
  refresh: 'Refresh',
  wall: 'Wall',
  barrier: 'Barrier',

  // Combat-Modifier:
  coalition: 'Coalition',
  legion: 'Legion',
  pierce: 'Pierce',
  rupture: 'Rupture',
  swipe: 'Swipe',
  drain: 'Drain',
  venom: 'Venom',
  hunt: 'Hunt',
  mark: 'Mark',

  // Damage-Dependent:
  berserk: 'Berserk',
  leech: 'Leech',
  poison: 'Poison',

  // Instant-Debuff:
  inhibit: 'Inhibit',
  sabotage: 'Sabotage',
  disease: 'Disease',

  // Triggered:
  allegiance: 'Allegiance',
  flurry: 'Flurry',
  valor: 'Valor',
  stasis: 'Stasis',
  summon: 'Summon',
  bravery: 'Bravery',
  enhance: 'Enhance'
} as const;

export type CardSkillType = keyof typeof cardSkillTypes;

export const cardFactions = {
  1: 'Imperial',
  2: 'Raider',
  3: 'Bloodthirsty',
  4: 'Xeno',
  5: 'Righteous',
  6: 'Progenitor'
} as const;

export type CardFaction = keyof typeof cardFactions;

/*const FactionIds = {
  1: 'imperial',
  2: 'raider',
  3: 'bloodthirsty',
  4: 'xeno',
  5: 'righteous',
  6: 'progenitor'
} as const;

export const FactionNames = {
  [Faction.allfactions]: '<Error>',
  [Faction.imperial]: 'Imperial',
  [Faction.raider]: 'Raider',
  [Faction.bloodthirsty]: 'Bloodthirsty',
  [Faction.xeno]: 'Xeno',
  [Faction.righteous]: 'Righteous',
  [Faction.progenitor]: 'Progenitor'
};*/

/*export function skill_faction(faction) {
  if (FactionIds[faction]) {
    return FactionIds[faction];
  }

  return FactionIds[0];
}*/

/*
export const CardType = Object.freeze({
  assault: 'assault',
  structure: 'structure',
  commander: 'commander',
  dominion: 'dominion'
});
*/

export const cardTypes = {
  commander: 'Commander',
  assault: 'Assault',
  structure: 'Structure',
  dominion: 'Dominion',
  dominionshard: 'Dominion Shard'
} as const;

export type CardType = keyof typeof cardTypes;

const CardSkillTriggers = {
  activate: 'On Activate',
  play: 'On Play',
  attacked: 'On Attacked',
  death: 'On Death'
} as const;

export type CardSkillTrigger = keyof typeof CardSkillTriggers;

/*
const Trigger = Object.freeze({
  activate: 'activate',
  play: 'play',
  attacked: 'attacked',
  death: 'death'
});

export const TriggerNames = {
  [Trigger.activate]: 'On Activate',
  [Trigger.play]: 'On Play',
  [Trigger.attacked]: 'On Attacked',
  [Trigger.death]: 'On Death'
};
*/

/*
type BaseSkill<T> = {
  id: T;
}

type BaseSkill2 = {
  id: string;
  x: number;

  all?: 1;
  c: number;
  s: string;
  s2: string;
  n: number;
  card_id: number;
  trigger?: string;
}
type SimpleSkill<T> = BaseSkill<T> & ValueSkill;
type SummonSkill<T> = Pick<BaseSkill<T>, 'id' | 'trigger' | 'card_id'>;

type ValueSkill = {
  x: number;
};

type FactionTargetingSkill = {
  y: Faction;
};

type AllTargetingSkill = {
  all?: 1;
};

type ActivationSkill = {

};

type CooldownedSkill = {
  c: number;
}

type Wall = BaseSkill<'wall'>;
type Rush = BaseSkill<'rush'>;
type Flying = BaseSkill<'flying'>;
type Armor = BaseSkill<'armored'> & ValueSkill;
*/

export class CardSkillSpec {
  all?: boolean;
  c?: number;
  card_id?: number;
  id: CardSkillType;
  n?: number;
  s?: CardSkillType;
  s2?: CardSkillType;
  trigger?: CardSkillTrigger;
  x?: number;
  y?: CardFaction;

  constructor(id: CardSkillType, x?: number, y?: CardFaction, n?: number, c?: number, s?: CardSkillType, s2?: CardSkillType, all?: boolean, card_id?: number, trigger?: CardSkillTrigger) {
    this.id = id;
    this.x = x;
    this.all = all;
    this.y = y;
    this.c = c;
    this.s = s;
    this.s2 = s2;
    this.n = n;
    this.card_id = card_id;
    this.trigger = trigger;
  }
}

export function getCardType(cardId: number): CardType | undefined {
  if ((cardId >= 1000 && cardId < 2000) || (cardId >= 25000 && cardId < 30000)) {
    return 'commander';
  }

  if ((cardId >= 2000 && cardId < 3000) || (cardId >= 8000 && cardId < 10000) || (cardId >= 17000 && cardId < 25000)) {
    return 'structure';
  }

  // if (cardId >= 3000 && cardId < 4000) {
  //   return 'action';
  // }

  if (cardId >= 50000 && cardId <= 51000) {
    return 'dominion';
  }

  if (cardId >= 43451 && cardId <= 43452) {
    return 'dominionshard';
  }

  if (cardId < 1000 || (cardId >= 4000 && cardId < 8000) || (cardId >= 10000 && cardId < 17000) || cardId >= 30000) {
    return 'assault';
  }
}

/*
export function rarityNames(rarity: number) {
  switch (rarity) {
    case 1:
      return 'Common';
    case 2:
      return 'Rare';
    case 3:
      return 'Epic';
    case 4:
      return 'Legendary';
    case 5:
      return 'Vindicator';
    case 6:
      return 'Mythic';
    default:
      return 'Longshot';
  }
}
*/

export const cardRarities = {
  1: 'Common',
  2: 'Rare',
  3: 'Epic',
  4: 'Legendary',
  5: 'Vindicator',
  6: 'Mythic'
} as const;

export type CardRarity = keyof typeof cardRarities;

type CommonCardLevel = 1 | 2 | 3;
type RareCardLevel = 1 | 2 | 3 | 4;
type EpicLegendaryVindicatorCardLevel = 1 | 2 | 3 | 4 | 5 | 6;
type CommanderQuadCardLevel = 1;
type RaidCardLevel = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export type CardLevel = CommonCardLevel | RareCardLevel | EpicLegendaryVindicatorCardLevel | RaidCardLevel | CommanderQuadCardLevel;

// eslint-disable-next-line no-unused-vars
/*class CardNode {
  constructor(nodeObject) {
    this.node = nodeObject;
  }

  get id() {
    return typeof this.node.id !== 'undefined' ? this.node.id.value : 0;
  }

  get name() {
    return typeof this.node.name !== 'undefined' ? this.node.name.value : '';
  }

  get picture() {
    return typeof this.node.picture !== 'undefined' ? this.node.picture.value : '';
  }

  get assetBundle() {
    return typeof this.node.asset_bundle !== 'undefined' ? this.node.asset_bundle.value : '';
  }

  get fusionLevel() {
    return typeof this.node.fusion_level !== 'undefined' ? this.node.fusion_level.value : 0;
  }

  get attack() {
    return typeof this.node.attack !== 'undefined' ? this.node.attack.value : 0;
  }

  get health() {
    return typeof this.node.health !== 'undefined' ? this.node.health.value : 0;
  }

  get cost() {
    return typeof this.node.cost !== 'undefined' ? this.node.cost.value : 0;
  }

  get rarity() {
    return typeof this.node.rarity !== 'undefined' ? this.node.rarity.value : 0;
  }

  get skills() {
    if (typeof this.node.skill === 'undefined') {
      return [];
    }

    if (Array.isArray(this.node.skill)) {
      return this.node.skill.map((skill) => skill._attributes);
    }

    if (this.node.skill._attributes) {
      return [this.node.skill._attributes];
    }

    return [];
  }

  get type() {
    return typeof this.node.type !== 'undefined' ? this.node.type.value : 0;
  }

  get set() {
    return typeof this.node.set !== 'undefined' ? this.node.set.value : 0;
  }

  get upgrades() {
    if (typeof this.node.upgrade === 'undefined') {
      return [];
    }

    if (Array.isArray(this.node.upgrade)) {
      return this.node.upgrade;
    }

    return [this.node.upgrade];
  }

  get maxLevel() {
    return this.upgrades.length + 1;
  }
}*/

export type CardLevels = {
  [key in CardLevel]: Card;
};

const cardSets = {
  1000: 'Standard',
  2000: 'Fusion',
  2500: 'Base Fusion',
  3000: 'Box',
  3001: 'Box Alternate',
  4000: 'PVE Reward',
  4001: 'PVE Reward Hold',
  4250: 'Mutant Reward',
  4500: 'PVP Reward',
  4501: 'PVP Reward Hold',
  4700: 'PVE Legacy Reward',
  4750: 'PVP Legacy Reward',
  5000: 'Invisible Reward Set',
  6000: 'Event Mission Cards',
  7000: 'Commander',
  8000: 'Fortress',
  8500: 'Dominion',
  9000: 'Chance',
  9500: 'Summoned',
  9999: 'waitlisted'
} as const;

type CardSet = keyof typeof cardSets;

type BaseCard = {
  asset_bundle?: string;
  base_id: number;
  category: string;
  delay: number;
  faction: number;
  health: number;
  id: number;
  level: number;
  fusion_level: FusionLevel;
  levels: CardLevels;
  name: string;
  picture: string;
  rarity: CardRarity;
  set: CardSet;
  skills: CardSkillSpec[];
  type: CardType;
}

type AssaultCard = {
  attack: number;
}

type CommanderCard = {
  type: typeof cardTypes.commander;
}

type CommonBaseCard = BaseCard & {
  rarity: 1;
}

type RareBaseCard = BaseCard & {
  rarity: 2;
}

type EpicBaseCard = BaseCard & {
  rarity: 3;
}

type LegendaryBaseCard = BaseCard & {
  rarity: 4;
}

type VindicatorBaseCard = BaseCard & {
  rarity: 5;
}

type MythicBaseCard = BaseCard & {
  rarity: 6;
}

type RareAssault = RareBaseCard & AssaultCard;

type RareCommander = RareBaseCard & CommanderCard;

export class Card {
  attack: number;
  asset_bundle?: string;
  base_id: number;
  category: CardCategory;
  delay: number;
  faction: CardFaction;
  gpCost?: number;
  health: number;
  id: number;
  level: number;
  fusion_level: FusionLevel;
  name: string;
  picture: string;
  rarity: CardRarity;
  rawXML: string;
  set: number;
  skills: CardSkillSpec[];
  levels: CardLevels;
  type: CardType;

  constructor() {
    this.attack = 0;
    this.base_id = 0;
    this.delay = 0;
    this.faction = 1;
    this.health = 0;
    this.id = 0;
    this.level = 1;
    this.fusion_level = 0;
    this.name = '';
    this.rarity = 1;
    this.set = 0;
    this.skills = [];
    this.levels = {} as CardLevels;
    // this.skills_on_play = [];
    // this.skills_on_attacked = [];
    // this.skills_on_death = [];
    // this.skill_value = {};
    // this.skill_trigger = {};
    this.type = 'assault';
    this.category = 'normal';
    // this.top_level_card = this;
    // this.recipe_cost = 0;
    // this.recipe_cards = new Map();
    // this.used_for_cards = new Map();
    this.picture = '';
    this.rawXML = '';
  }

  addSkill(
    id: CardSkillType,
    x?: number,
    y?: CardFaction,
    n?: number,
    c?: number,
    s?: CardSkillType,
    s2?: CardSkillType,
    all?: true,
    card_id?: number,
    trigger?: CardSkillTrigger
  ): void {
    /*

    let storage = [];

    switch (trigger) {
      case Trigger.activate:
        storage = this.skills;
        break;
      case Trigger.play:
        storage = this.skills_on_play;
        break;
      // APN
      case Trigger.attacked:
        storage = this.skills_on_attacked;
        break;
      case Trigger.death:
        storage = this.skills_on_death;
        break;
      default:
        break;
    }

    if (storage) {
      const filterSkills = skill => skill.id !== id;

      this.skills = this.skills.filter(filterSkills);
      this.skills_on_play = this.skills_on_play.filter(filterSkills);
      this.skills_on_death = this.skills_on_death.filter(filterSkills);
      this.skills_on_attacked = this.skills_on_attacked.filter(filterSkills);
    }
    */

    this.skills.push(new CardSkillSpec(id, x, y, n, c, s, s2, all, card_id, trigger));
    // this.skill_value[id] = x || n || card_id || 1;
    // this.skill_trigger[id] = trigger;
  }

  get maxLevel(): CardLevel {
    return Math.max(...Object.keys(this.levels).map((level) => parseInt(level, 10))) as unknown as CardLevel;
  }

  getInfoForXML(isUpgradeCard = false): any {
    const {
      asset_bundle,
      attack,
      base_id,
      category,
      delay,
      faction,
      fusion_level,
      gpCost,
      health,
      id,
      level,
      levels,
      name,
      picture,
      rarity,
      set,
      skills,
      type
    } = this;

    const ownProperties = Object.keys(this);

    return {
      ...(id && base_id && base_id === id && { id }),
      ...(id && base_id && base_id !== id && id && { card_id: id }),
      ...(level && level > 1 && { level }),
      ...(ownProperties.includes('name') && { name }),
      ...(ownProperties.includes('picture') && { picture }),
      ...(ownProperties.includes('asset_bundle') && asset_bundle && { asset_bundle }),
      ...(ownProperties.includes('category') && category === 'fortress_defense' && { fortress_type: '1' }),
      ...(ownProperties.includes('category') && category === 'fortress_siege' && { fortress_type: '2' }),
      ...(ownProperties.includes('gpCost') && gpCost && { gp_cost: gpCost }),
      ...(ownProperties.includes('fusion_level') && fusion_level !== 0 && { fusion_level }),
      ...(type === 'assault' && !isUpgradeCard && ownProperties.includes('attack') && { attack }),
      ...(ownProperties.includes('health') && { health }),
      ...(type === 'assault' && isUpgradeCard && ownProperties.includes('attack') && { attack }),
      ...(type !== 'commander' && ownProperties.includes('delay') && { cost: delay }),
      ...(ownProperties.includes('rarity') && { rarity }),
      ...(ownProperties.includes('skills') && { skill: skills.map(Card.getSkillInfoForXML) }),
      ...(ownProperties.includes('faction') && { type: faction }),
      ...(ownProperties.includes('set') && { set }),
      ...(id &&
        base_id &&
        id === base_id &&
        ownProperties.includes('levels') && {
          upgrade: Object.values(levels)
            .filter((card) => card.id !== this.base_id)
            .map((card) => card.getInfoForXML(true))
        })
    };
  }

  static getSkillInfoForXML(skillNode: CardSkillSpec): parser.ElementCompact {
    const ownProperties = Object.keys(skillNode);
    const { all, c, card_id, id, n, s, s2, trigger, x, y } = skillNode;

    return {
      _attributes: {
        ...(ownProperties.includes('id') && { id }),
        ...(ownProperties.includes('n') && n && { n }),
        ...(ownProperties.includes('x') && x && { x }),
        ...(ownProperties.includes('trigger') && trigger && { trigger }),
        ...(ownProperties.includes('all') && all && { all: 1 }),
        ...(ownProperties.includes('y') && y && { y }),
        ...(ownProperties.includes('c') && c && { c }),
        ...(ownProperties.includes('s') && s && { s }),
        ...(ownProperties.includes('s2') && s2 && { s2 }),
        ...(ownProperties.includes('card_id') && card_id && { card_id })
      }
    };
  }

  getCardParamsLine() {
    return `${this.type === 'assault' ? this.attack : '-'}/${this.health}/${
      this.type === 'assault' || this.type === 'structure' ? this.delay : '-'
    }`;
  }

  getCardFactionLine(): string {
    const cardType = getCardType(this.id);
    return `${cardRarities[this.rarity]} ${cardFactions[this.faction]} ${cardType !== undefined ? cardTypes[cardType] : 'Unknown'}`;
  }

  isTopLevelCard(): boolean {
    return this.level === this.maxLevel;
  }

  isLowLevelCard(): boolean {
    return this.base_id === this.id;
  }

  /*
  upgraded(): Card {
    return this.is_top_level_card() ? this : this.used_for_cards.entries().next().value;
  }

  downgraded(): Card {
    return this.is_low_level_card() ? this : this.recipe_cards.entries().next().value;
  }
  */
}

export function isKnownSkill(skill: string | undefined): skill is CardSkillType {
  if (skill !== undefined && skill in cardSkillTypes) {
    return true;
  }

  console.error(`WARNING: unknown skill type: ${skill}`);

  return false;
}

export function isKnownSkillTrigger(skillTrigger: string): skillTrigger is CardSkillTrigger {
  if (skillTrigger in CardSkillTriggers) {
    return true;
  }

  console.error(`WARNING: unknown skill trigger: ${skillTrigger}`);

  return false;
}

export function getSkillLine(skill: CardSkillSpec, getCardNameById: (id: number) => string | null = (id) => `[${id}]`) {
  return (
    (skill.trigger ? `${CardSkillTriggers[skill.trigger]}: ` : '') +
    cardSkillTypes[skill.id] +
    (skill.all ? ' All' : '') +
    (skill.n ? ` ${skill.n}` : '') +
    (skill.y ? ` ${cardFactions[skill.y]}` : '') +
    (!skill.y && skill.n && skill.x ? ' Assault' : '') +
    (skill.s ? ` ${cardSkillTypes[skill.s]}` : '') +
    (skill.s2 ? ` to ${cardSkillTypes[skill.s2]}` : '') +
    (skill.x ? ` ${skill.x}` : '') +
    (skill.card_id ? ` ${getCardNameById(skill.card_id)}` : '') +
    (skill.c ? ` every ${skill.c}` : '')
  );
}



/*
function skillText(skill, summonedName = 'Doge') {
  let result = SkillNames[skill.id];

  if (result !== skill.id) {
    if (skill.trigger) {
      result = `${TriggerNames[skill.trigger]}: ${result}`;
    }

    if (skill.all) {
      result += ' All';
    }

    if (skill.n) {
      result += ` ${skill.n}`;
    }

    if (skill.y) {
      result += ` ${FactionNames[skill.y]}`;
    } else if (skill.n && skill.x) {
      result += ' Assault';
    }

    if (skill.s) {
      result += ` ${SkillNames[skill.s]}`;
    }

    if (skill.s2) {
      result += ` to ${SkillNames[skill.s2]}`;
    }
    if (skill.x) {
      result += ` ${skill.x}`;
    }

    if (skill.card_id === 'summon') {
      result += ` ${summonedName}`;
    }

    if (skill.c) {
      result += ` every ${skill.c}`;
    }
  }

  return result;
}
*/

/*
function getCardTriggerText(trigger) {
  switch (trigger) {
    case 'activate':
      return 'On Activate: ';
    case 'attacked':
      return 'On Attacked: ';
    case 'death':
      return 'On Death: ';
    case 'play':
      return 'On Play: ';
    default:
      return '';
  }
}

function getSkillName(skill) {
  return skills[skill] || ucfirst(skill);
}
*/

/*function unitType(id: number) {
  if ((id >= 1000 && id < 2000) || (id >= 25000 && id < 30000)) {
    return 'Commander';
  }

  if ((id >= 2000 && id < 3000) || (id >= 8000 && id < 10000) || (id >= 17000 && id < 25000)) {
    return 'Structure';
  }

  if (id >= 50000 && id < 55000) {
    return 'Dominion';
  }

  return 'Assault';
}*/

/*
function drawFromSources(ctx, obj, dx, dy, dw, dh) {
  const img = document.querySelector(`#${obj.id}`);

  ctx.drawImage(img, obj.x, obj.y, obj.width, obj.height, dx, dy, dw, dh);
}

function drawLevel(xCard, ctx, isSmall = false) {
  const fused = xCard.fusion_level > 0 ? 1 : 0;
  // max level
  const ml = xCard.maxLevel;
  // half level
  const hl = ml > 6 ? Math.ceil(ml / 2) : ml;
  let x = Math.floor((160 - 11 * hl) / 2);
  let y = 205;
  let dxy = 11;

  if (isSmall) {
    x = Math.floor(x / 2);
    y = Math.floor(y / 2);
    dxy = Math.floor(dxy / 2);
  }

  for (let i = 0; i < ml; i += 1) {
    // sort of linebreak at level hl + 1.
    if (i === hl) {
      x = Math.floor((160 - 11 * (ml - hl)) / 2);
      y -= dxy;
      if (isSmall) {
        x = Math.floor(x / 2);
      }
    }

    const filled = i < xCard.level ? 1 : 0;
    // const path = `/root/icon[fused=${fused} and filled=${filled}]/source[1]`;
    const path = cardConfig[`icon_${fused ? 'fused' : 'unfused'}_${filled ? 'full' : 'empty'}`].source;

    drawFromSources(ctx, path, x, y, dxy, dxy);
    x += dxy;
  }
}

function drawArialText(ctx, str, dx, dy, maxWidth) {
  let x = 8;
  let wsIdX;
  let postDy = dy;
  let isLong = false;

  do {
    ctx.font = `bold ${x}pt Arial`;
    x -= 1;
  } while (ctx.measureText(str).width > maxWidth && x > 4);

  postDy = dy;

  if (x === 4) {
    // whitespace index.
    wsIdX = 0;
    isLong = true;

    const sl = Math.floor(str.length / 2);

    for (let i = 0; i < sl; i += 1) {
      // start searching from middle.
      if (str.charAt(sl - i) === ' ') {
        wsIdX = sl - i;
        break;
      } else if (str.charAt(sl + i + 1) === ' ') {
        wsIdX = sl + i + 1;
        break;
      }
    }
  } else {
    postDy += (x - 8) / 2;
  }

  if (isLong) {
    ctx.font = 'bold 6pt Arial';
    ctx.fillText(str.slice(0, wsIdX), dx, postDy - 4);
    ctx.fillText(str.slice(wsIdX + 1, str.length), dx, postDy + 4);
  } else {
    ctx.fillText(str, dx, postDy);
  }
}

function drawSkill(ctx, c, isSmall = false) {
  let x;

  for (let i = 0, l = Math.min(3, c.skills.length); i < l; i += 1) {
    // const path = `/root/skillType[id='${c.skills[i].id}']/source[1]`;
    const path = cardConfig.skillType[c.skills[i].id].source;
    if (isSmall) {
      drawFromSources(ctx, path, 8 + 24 * i, 76, 16, 16);
    } else {
      drawFromSources(ctx, path, 14, 148 + 16 * i, 16, 16);
      x = c.skills[i].skillText();
      drawArialText(ctx, x, 32, 160 + 16 * i, 115);
    }
  }
}

export function drawUnit(ctx, xCard) {
  if (xCard) {
    ctx.font = '16pt Optimus';
    ctx.fillStyle = 'white';

    // let path = `/root/style[type=${xCard.type} and rarity=${xCard.rarity}]/source[1]`;
    let path =
      cardConfig.style[
        `card_${cardConfig.unitType[xCard.type].name.toLowerCase()}_${cardConfig.unitRarity[
          xCard.type
        ].name.toLowerCase()}`
      ].source;

    drawFromSources(ctx, path, 0, 0, 160, 220);

    if (xCard.fusion_level) {
      // path = `/root/frame[fusion_level='${xCard.fusion_level}']/source[1]`;
      path = cardConfig.frame[`fused_card_overlay${xCard.fusion_level + 1}}`].source;
      drawFromSources(ctx, path, 0, 0, 160, 220);
    }

    drawLevel(xCard, ctx);

    // path = `/root/icon[name='icon_${Card.unitType(xCard.card_id).toLowerCase()}_common']/source[1]`;
    path = cardConfig.icon[`icon_${unitType(xCard.card_id).toLowerCase()}_common`].source;
    drawFromSources(ctx, path, 2, 2, 24, 24);

    if (xCard.cost) {
      // path = "/root/icon[name='cost_container']/source[1]";
      path = cardConfig.icon.cost_container.source;
      drawFromSources(ctx, path, 120, 26, 32, 32);
      ctx.textAlign = 'center';
      ctx.fillText(xCard.cost, 136, 49);
    }

    ctx.font = '14pt Optimus';

    if (unitType(xCard.card_id) === 'Assault') {
      if (xCard.attack === undefined) {
        // eslint-disable-next-line no-param-reassign
        xCard.attack = 0;
      }

      ctx.textAlign = 'left';
      ctx.fillText(xCard.attack, 24, 215);
    }

    if (xCard.health !== undefined) {
      ctx.textAlign = 'right';
      ctx.fillText(xCard.health, 136, 215);
    }

    ctx.font = 'bold 8pt Arial';
    ctx.textAlign = 'left';

    drawArialText(ctx, xCard.name, 35, 18, 120);
    drawArialText(ctx, factionNames(xCard.type), 10, 140, 140);
    drawSkill(ctx, xCard);
  }
}
*/

export function typeToNumber(cardType: CardType | undefined) {
  switch (cardType) {
    case 'assault':
      return 1;
    case 'commander':
      return 2;
    case 'structure':
      return 3;
    case 'dominion':
    case 'dominionshard':
      return 4;
    // case 'action':
    default:
      return 5;
  }
}
