import { CARD_HEIGHT, CARD_WIDTH } from '../../constants';
import { CardFaction, CardRarity, CardSkillSpec, CardType, FusionLevel, getSkillLine } from '../../services/cardUtils';
import { cardConfig } from './cardConfig';

class TUCardCanvas {
  context: CanvasRenderingContext2D;
  framesImage: HTMLImageElement;
  height: number;
  image: HTMLImageElement;
  ratio: number;
  skillsImage: HTMLImageElement;
  width: number;

  constructor(
    canvas: HTMLCanvasElement,
    image: HTMLImageElement,
    framesImage: HTMLImageElement,
    skillsImage: HTMLImageElement,
    width = CARD_WIDTH,
    height = CARD_HEIGHT
  ) {
    const context = canvas.getContext('2d');

    if (context === null) {
      throw new Error('canvas not found');
    }

    this.context = context;
    this.framesImage = framesImage;
    this.height = height;
    this.image = image;
    this.ratio = window.devicePixelRatio || 1;
    this.skillsImage = skillsImage;
    this.width = width;

    // eslint-disable-next-line no-param-reassign
    canvas.height = height * this.ratio;
    // eslint-disable-next-line no-param-reassign
    canvas.width = width * this.ratio;

    this.context.setTransform(this.ratio, 0, 0, this.ratio, 0, 0);
    this.context.font = '16pt Optimus';
    this.context.fillStyle = 'white';

    this.clear();
  }

  clear(): this {
    if (this.context) {
      this.context.clearRect(0, 0, this.width, this.height);
    }

    return this;
  }

  drawArialText(str: string, dx: number, dy: number, maxWidth: number): void {
    let x = 8;
    let postDy = dy;
    let isLong = false;

    do {
      this.context.font = `bold ${x}pt Arial`;
      x -= 1;
    } while (this.context.measureText(str).width > maxWidth && x > 4);

    if (x === 4) {
      let wsIdX = 0;
      const sl = Math.floor(str.length / 2);

      for (let i = 0; i < sl; i += 1) {
        // start searching from middle.
        if (str.charAt(sl - i) === ' ') {
          wsIdX = sl - i;
          break;
        } else if (str.charAt(sl + i + 1) === ' ') {
          wsIdX = sl + i + 1;
          break;
        }
      }

      this.context.font = 'bold 6pt Arial';
      this.context.fillText(str.slice(0, wsIdX), dx, postDy - 4);
      this.context.fillText(str.slice(wsIdX + 1, str.length), dx, postDy + 4);
    } else {
      postDy += (x - 8) / 2;

      this.context.fillText(str, dx, postDy);
    }
  }

  drawUnitFrame(rarity: CardRarity, faction: CardFaction): this {
    const { height, width, x, y } =
      cardConfig.style[
        `card_${cardConfig.unitType[faction].name.toLowerCase()}_${cardConfig.unitRarity[
          rarity
        ].name.toLowerCase()}` as unknown as keyof typeof cardConfig.style
      ].source;

    this.context.drawImage(this.framesImage, x, y, width, height, 0, 0, this.width, this.height);

    return this;
  }

  drawUnitImage(): this {
    this.context.drawImage(this.image, 0, 0, 150, 125, 5, 20, 150, 125);

    return this;
  }

  drawUnitFusion(fusionLevel: FusionLevel): this {
    if (
      fusionLevel > 0 &&
      typeof cardConfig.frame[`fused_card_overlay${fusionLevel + 1}` as unknown as keyof typeof cardConfig.frame] !==
        'undefined'
    ) {
      const { height, width, x, y } =
        cardConfig.frame[`fused_card_overlay${fusionLevel + 1}` as unknown as keyof typeof cardConfig.frame].source;

      this.context.drawImage(this.framesImage, x, y, width, height, 0, 0, CARD_WIDTH, CARD_HEIGHT);
    }

    return this;
  }

  drawUnitLevel(level: number, maxLevel: number, fusionLevel: FusionLevel, isSmall: boolean = false): this {
    const fused = fusionLevel > 0 ? 1 : 0;
    // half level
    const halfLevel = maxLevel > 6 ? Math.ceil(maxLevel / 2) : maxLevel;
    let x = Math.floor((CARD_WIDTH - 11 * halfLevel) / 2);
    let y = 205;
    let dxy = 11;

    if (isSmall) {
      x = Math.floor(x / 2);
      y = Math.floor(y / 2);
      dxy = Math.floor(dxy / 2);
    }

    for (let i = 0; i < maxLevel; i += 1) {
      // sort of linebreak at level hl + 1.
      if (i === halfLevel) {
        x = Math.floor((CARD_WIDTH - 11 * (maxLevel - halfLevel)) / 2);
        y -= dxy;

        if (isSmall) {
          x = Math.floor(x / 2);
        }
      }

      const filled = i < level ? 1 : 0;
      // const path = `/root/icon[fused=${fused} and filled=${filled}]/source[1]`;
      const path =
        cardConfig.icon[
          `icon_${fused ? 'fused' : 'unfused'}_${filled ? 'full' : 'empty'}` as unknown as keyof typeof cardConfig.icon
        ];

      this.context.drawImage(this.framesImage, path.x, path.y, path.width, path.height, x, y, dxy, dxy);
      x += dxy;
    }

    return this;
  }

  drawUnitType(cardType: CardType): this {
    const { height, width, x, y } = cardConfig.icon[`icon_${cardType}` as unknown as keyof typeof cardConfig.icon];

    this.context.drawImage(this.framesImage, x, y, width, height, 2, 2, 24, 24);

    return this;
  }

  drawUnitCost(cost: number): this {
    if (cost > 0) {
      const { height, width, x, y } = cardConfig.icon.cost_container;

      this.context.font = '16pt Optimus';
      this.context.textAlign = 'center';

      this.context.drawImage(this.framesImage, x, y, width, height, 120, 26, 32, 32);
      this.context.fillText(cost.toString(), 136, 49);
    }

    return this;
  }

  drawUnitAttack(attack: number = 0, unitType: CardType): this {
    if (unitType === 'assault') {
      this.context.font = '14pt Optimus';
      this.context.textAlign = 'left';
      this.context.fillText(attack.toString(), 24, 215);
    }

    return this;
  }

  drawUnitHealth(health: number = 0, unitType: CardType): this {
    this.context.font = '14pt Optimus';
    this.context.textAlign = 'right';
    this.context.fillText(health.toString(), 136, 215);

    return this;
  }

  drawUnitName(name: string): this {
    this.context.font = 'bold 8pt Arial';
    this.context.textAlign = 'left';

    this.drawArialText(name, 35, 18, 120);

    return this;
  }

  drawUnitFaction(factionName: string): this {
    this.context.font = 'bold 8pt Arial';
    this.context.textAlign = 'left';

    this.drawArialText(factionName, 10, 140, 140);

    return this;
  }

  drawSkills(skills: CardSkillSpec[], isSmall: boolean = false, getCardNameById: (id: number) => string | null): this {
    for (let i = 0, l = Math.min(3, skills.length); i < l; i += 1) {
      // const path = `/root/skillType[id='${skills[i].id}']/source[1]`;
      // console.log(skills[i].id, cardConfig.skillType[skills[i].id]);
      // console.log(skills, isSmall);
      const { height, width, x, y } = cardConfig.skillType[skills[i].id].source;

      if (isSmall) {
        this.context.drawImage(this.skillsImage, x, y, width, height, 8 + 24 * i, 76, 16, 16);
      } else {
        this.context.drawImage(this.skillsImage, x, y, width, height, 14, 148 + 16 * i, 16, 16);
        this.drawArialText(getSkillLine(skills[i], getCardNameById), 32, 160 + 16 * i, 115);
      }
    }

    return this;
  }
}

export default TUCardCanvas;
