import { createStyles } from '@material-ui/core/styles';

import { CARD_HEIGHT, CARD_WIDTH } from '../../constants';

export default () => createStyles({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardCanvas: {
    height: CARD_HEIGHT,
    width: CARD_WIDTH
  },
  cardWrapper: {
    borderRadius: 3,
    height: CARD_HEIGHT,
    margin: '0 auto',
    overflow: 'hidden',
    position: 'relative',
    width: CARD_WIDTH
  },
  paper: {
    paddingBottom: 50
  },
  appBar: {
    top: 'auto',
    bottom: 0
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto'
  }
});
