import React from 'react';

import {
  Card,
  CardFaction,
  CardRarity, CardType, FusionLevel,
  getCardType,
  typeToNumber
} from "../../../services/cardUtils";
import {
  CardAttack,
  CardDelay,
  CardFusionLevel,
  CardFusionWrapper,
  CardHealth, CardLevel,
  CardLineWrapper,
  CardName,
  CardNameAndLevelBock,
  CardNameWrapper,
  CardTypeIconWrapper,
  CardTypeWrapper,
  StyledCardTypeIcon
} from "./styles";

interface CardProps {
  assetBundle?: string;
  attack: number;
  cost: number;
  faction: CardFaction;
  fusionLevel: FusionLevel;
  health: number;
  id: number;
  level: number;
  name: string;
  picture: string;
  rarity: CardRarity;
  type: CardType;
  levels: { [key in typeof CardLevel]: Card }
}

interface CardLineProps extends CardProps {
  onCardLineClick: React.MouseEventHandler<HTMLDivElement>;
}

function CardLine({
  assetBundle,
  attack,
  cost,
  faction,
  fusionLevel,
  health,
  id,
  name,
  picture,
  rarity,
  onCardLineClick
}: CardLineProps): JSX.Element {
  const cardType = getCardType(id);

  return (
    <CardLineWrapper onClick={onCardLineClick}>
      <CardTypeWrapper f={faction} r={rarity}>
        <CardTypeIconWrapper r={rarity}>
          <StyledCardTypeIcon type={typeToNumber(cardType)} />
        </CardTypeIconWrapper>
      </CardTypeWrapper>
      <CardFusionWrapper fl={fusionLevel > 0}>
        <CardNameAndLevelBock>
          <CardNameWrapper f={faction}>{/* b={assetBundle} p={picture} */}
            <CardName>{name}</CardName>
            <CardFusionLevel fl={fusionLevel} />
          </CardNameWrapper>
          {/* <CardLevels>
            {upgrades &&
              Object.keys(upgrades).map(
                lv =>
                  console.log(lv) || (
                    <CardLevel
                      key={`card-level-${lv}`}
                      on={parseInt(lv, 10) === 1 || parseInt(lv, 10) <= parseInt(level, 10)}
                      fon={fusionLevel > 0}
                    />
                  )
              )}
          </CardLevels> */}
        </CardNameAndLevelBock>
        {cardType === 'assault' && <CardAttack a={attack}>{attack}</CardAttack>}
        <CardHealth h={health}>{health}</CardHealth>
        {(cardType === 'assault' || cardType === 'structure') && <CardDelay>{cost}</CardDelay>}
      </CardFusionWrapper>
    </CardLineWrapper>
  );
}

export default CardLine;
