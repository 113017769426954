import { createStyles, Theme } from '@material-ui/core/styles';

export default ({ spacing }: Theme) =>
  createStyles({
    listWrapper: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      overflow: 'hidden'
    },
    listSubheader: {
      background: '#ffffff',
      margin: spacing(1, 0)
    },
    filteredAmount: {
      margin: spacing(1, 0)
    },
    cardsList: {
      height: '100%',
      margin: spacing(1, 0),
      overflowX: 'hidden',
      overflowY: 'auto'
    }
  });
