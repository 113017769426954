// import Input from '@material-ui/core/Input';
import InputBase from '@material-ui/core/InputBase';
// import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
// import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles';

interface SearchInputProps {
  value: string;
  onChange: (..._: any[]) => void;
}

const useStyles = makeStyles(styles);

const SearchInput = ({ value, onChange }: SearchInputProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Filter by Name"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{ 'aria-label': 'search' }}
        type="search"
        onChange={onChange}
      />
    </div>
    /*
    <Paper className={classes.root} elevation={0}>
      <SearchIcon className={classes.icon} />
      <TextField
        defaultValue={value}
        disableUnderline
        variant="outlined"
        size="small"
        type="search"
        onChange={onChange}
      />
    </Paper>
    */
  );
};

/*

SearchInput.defaultProps = {
  value: '',

  onChange: () => {return;}
};

SearchInput.propTypes = {
  value: PropTypes.string,

  onChange: PropTypes.func
};
*/

export default SearchInput;
