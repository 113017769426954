import parser from 'xml-js';

import {
  Card,
  CardFaction,
  cardFactions,
  CardLevel,
  cardRarities,
  CardRarity,
  CardSkillTrigger,
  CardSkillType,
  cardTypes,
  FusionLevel,
  isKnownSkill,
  isKnownSkillTrigger
} from './cardUtils';

const xmlConvertOption = {
  compact: true,
  spaces: '\t',
  textKey: 'value',
  ignoreComment: true
};

export function convertCardsToXML(cards: Card[]) {
  return parser.js2xml(
    {
      _declaration: {
        _attributes: {
          version: '1.0',
          encoding: 'UTF-8'
        }
      },
      root: {
        unit: cards.map((card) => card.levels['1'].getInfoForXML())
      }
    },
    xmlConvertOption
  );
}

export function convertCardToXML(card: Card): string {
  return parser.js2xml({ unit: card.getInfoForXML() }, xmlConvertOption);
}

function isKnownRarityId(rarityId: number): rarityId is CardRarity {
  if (Object.keys(cardRarities).map(Number).includes(rarityId)) {
    return true;
  }

  console.error(`Unknown rarity id: ${rarityId}`);

  return false;
}

function isKnownFactionId(factionId: number): factionId is CardFaction {
  if (Object.keys(cardFactions).map(Number).includes(factionId)) {
    return true;
  }

  console.error(`Unknown factionId: ${factionId}`);

  return false;
}

function isKnownFusionLevel(fusionLevel: number): fusionLevel is FusionLevel {
  if ([0, 1, 2].includes(fusionLevel)) {
    return true;
  }

  console.error(`Unknown fusion level: ${fusionLevel}`);

  return false;
}

function getRarity(value?: string): CardRarity | undefined {
  const rarity = value ? Number.parseInt(value, 10) : -1;

  if (rarity in cardRarities) {
    return rarity as CardRarity;
  }
}

// eslint-disable-next-line no-unused-vars
function parseCardNode(prevCard: Card, cardNode: { [key: string]: any }) {
  let fortressType;
  const card = prevCard;

  // eslint-disable-next-line no-restricted-syntax
  for (const child in cardNode) {
    if (child === 'id') {
      card.base_id = Number.parseInt(cardNode[child].value, 10);
      card.id = Number.parseInt(cardNode[child].value, 10);
    } else if (child === 'card_id' && !('id' in cardNode)) {
      card.id = Number.parseInt(cardNode[child].value, 10);
    } else if (child === 'name') {
      card.name = cardNode[child].value;
    } else if (child === 'attack') {
      card.attack = Number.parseInt(cardNode[child].value, 10);
    } else if (child === 'health') {
      card.health = Number.parseInt(cardNode[child].value, 10);
    } else if (child === 'cost') {
      card.delay = Number.parseInt(cardNode[child].value, 10);
    } else if (child === 'rarity') {
      const rarityId = Number.parseInt(cardNode[child].value, 10);

      if (isKnownRarityId(rarityId)) {
        card.rarity = rarityId;
      }
    } else if (child === 'type') {
      const factionId = Number.parseInt(cardNode[child].value, 10);

      if (isKnownFactionId(factionId)) {
        card.faction = factionId;
      }
    } else if (child === 'fortress_type') {
      fortressType = Number.parseInt(cardNode[child].value, 10);
    } else if (child === 'gp_cost') {
      card.gpCost = Number.parseInt(cardNode[child].value, 10);
    } else if (child === 'set') {
      card.set = Number.parseInt(cardNode[child].value, 10);
    } else if (child === 'level') {
      card.level = Number.parseInt(cardNode[child].value, 10);
    } else if (child === 'fusion_level') {
      const fusionLevel = Number.parseInt(cardNode[child].value, 10);

      if (isKnownFusionLevel(fusionLevel)) {
        card.fusion_level = fusionLevel;
      }
    } else if (child === 'asset_bundle') {
      card.asset_bundle = cardNode[child].value;
    } else if (child === 'picture') {
      card.picture = cardNode[child].value;
    }
  }

  if (!('level' in cardNode)) {
    card.level = 1;
  }

  /*
  if (!('name' in card_node)) {
    card.name = prev_card.name;
  }
  */

  if (card.id) {
    // [0 .. 999]
    if (card.id < 1000) {
      card.type = 'assault';
    }
    // [1000 .. 1999]
    else if (card.id < 2000) {
      card.type = 'commander';
    }
    // [2000 .. 2999]
    else if (card.id < 3000) {
      card.type = 'structure';

      // fortress
      if (card.id >= 2700 && card.id < 2997) {
        if (fortressType !== undefined) {
          switch (fortressType) {
            case 1:
              card.category = 'fortress_defense';
              break;
            case 2:
              card.category = 'fortress_siege';
              break;
            default:
              console.error(`WARNING: parsing card ["${card.id}"]: unsupported fortress_type="${fortressType}"`);
          }
        } else if (card.category === undefined && (card.id < 2748 || card.id >= 2754)) {
          // except Sky Fortress
          console.error(`WARNING: parsing card ["${card.id}"]: expected fortress_type node`);
        }
      }
    }
    // [3000 ... 7999]
    else if (card.id < 8000) {
      card.type = 'assault';
    }
    // [8000 .. 9999]
    else if (card.id < 10000) {
      card.type = 'structure';
    }
    // [10000 .. 16999]
    else if (card.id < 17000) {
      card.type = 'assault';
    }
    // [17000 .. 24999]
    else if (card.id < 25000) {
      card.type = 'structure';
    }
    // [25000 .. 29999]
    else if (card.id < 30000) {
      card.type = 'commander';
    }
    // [30000 .. 50000]
    else if (card.id < 50001) {
      card.type = 'assault';

      if (card.id === 43451 || card.id === 43452) {
        card.category = 'dominion_material';
      }
    }
    // [50001 .. 55000]
    else if (card.id < 55001) {
      card.type = 'structure';
      card.category = 'dominion_alpha';
    }
    // [55001+]
    else {
      card.type = 'assault';
    }
  }

  // fortresses
  if (card.set === 8000) {
    if (card.type !== 'structure') {
      console.error(
        `WARNING: parsing card ["${
          card.id
        }"]: set 8000 supposes fortresses card that implies type Structure, but card has type "${cardTypes[card.type]}"`
      );
    }

    // assume all other fortresses as conquest towers
    if (card.category === 'normal') {
      card.category = 'fortress_conquest';
    }
  }

  if (cardNode.skill !== undefined) {
    // inherit no skill if there is skill node
    card.skills = [];
    // card.skills_on_play = [];
    // card.skills_on_attacked = [];
    // card.skills_on_death = [];

    const cardNodeSkills = Array.isArray(cardNode.skill) ? cardNode.skill : [cardNode.skill];

    for (const { _attributes: skill_node } of cardNodeSkills) {
      // const skill_node = skill_node_._attributes;
      const skill_id: CardSkillType | undefined = isKnownSkill(skill_node.id) ? skill_node.id : undefined;

      if (skill_id === undefined) {
        continue;
      }

      const trigger: CardSkillTrigger | undefined = skill_node.trigger && isKnownSkillTrigger(skill_node.trigger) ? skill_node.trigger : undefined;
      const x = skill_node.x ? parseInt(skill_node.x, 10) : undefined;
      let y: CardFaction | undefined = undefined;

      if (skill_node.y !== undefined) {
        const factionId = parseInt(skill_node.y, 10);

        if (isKnownFactionId(factionId)) {
          y = factionId;
        }
      }

      const n = skill_node.n ? parseInt(skill_node.n, 10) : undefined;
      const s = skill_node.s && isKnownSkill(skill_node.s) ? skill_node.s : undefined;
      const s2 = skill_node.s2 && isKnownSkill(skill_node.s2) ? skill_node.s2 : undefined;
      const all = skill_node.all === '1' ? true : undefined;
      const card_id = skill_node.card_id ? parseInt(skill_node.card_id, 10) : undefined;
      let c;

      if (skill_node.c) {
        c = parseInt(skill_node.c, 10);
      } else if (skill_id === 'flurry') {
        c = 1;
      }

      card.addSkill(skill_id, x, y, n, c, s, s2, all, card_id, trigger);
    }
  }

  return card;
}

export function parseXMLFile(xml: string): Card[] {
  let parsedResult: parser.ElementCompact = parser.xml2js(xml.includes('<root>') ? xml : `<root>${xml}</root>`, {
    compact: true,
    trim: true,
    nativeType: true,
    textKey: 'value'
  });

  //
  // const parsedResult = JSON.parse(parseResult);
  const cards = [];
  const unitNode = parsedResult.root ? parsedResult.root.unit : parsedResult.unit;

  if (unitNode) {
    const unitNodeArray = Array.isArray(unitNode) ? unitNode : [unitNode];
    // let i = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const unit of unitNodeArray) {
      // const card = new CardNode(unit);
      //
      // cards.push(card);

      /*
      if (card.upgrades.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        let prevCard = card;

        for (const upgrade of card.upgrades) {
          const cardUpgrade = Object.create(prevCard);



          cards.push(card);
        }
      }
      */
      // i += 1;

      const card = parseCardNode(new Card(), unit);

      card.levels['1'] = card;
      card.rawXML = parser.js2xml({ unit: unit }, xmlConvertOption);

      cards.push(card);

      if (unit.upgrade) {
        if (!Array.isArray(unit.upgrade)) {
          // eslint-disable-next-line no-param-reassign
          unit.upgrade = [unit.upgrade];
        }

        let top_card = card;

        // eslint-disable-next-line no-restricted-syntax
        for (const upgrade_node of unit.upgrade) {
          // const pre_upgraded_card = top_card;
          // TODO: check that it works as expected
          // { ...top_card };
          // console.log(upgrade_node);
          top_card = parseCardNode(Object.create(top_card), upgrade_node);
          // cards.push(top_card);
          // console.log(top_card);
          card.levels[`${top_card.level}` as unknown as CardLevel] = top_card;
          // console.log(top_card.id, top_card.level, card.levels);
          // if (top_card.type === CardType.commander) {
          //   // Commanders cost twice
          //   top_card.recipe_cost = 2 * upgrade_cost[pre_upgraded_card.level];
          // } else {
          //   top_card.recipe_cost = upgrade_cost[pre_upgraded_card.level];
          // }
          // top_card.recipe_cards = new Map();
          // top_card.recipe_cards.set(pre_upgraded_card, 1);
          // pre_upgraded_card.used_for_cards.set(top_card, 1);
        }

        // card.top_level_card = top_card;
      }
    }
  } else {
    throw new Error('There are no units in the file.');
  }
  // console.log('cards', cards);
  return cards;
}
