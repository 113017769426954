import styled, { css } from 'styled-components';

import TypeIcon from '../../TypeIcon';
import { FusionLevel } from "../../../services/cardUtils";

export const CardLineWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 31px;
  color: white;
  user-select: none;
  background: linear-gradient(to top, rgba(67, 68, 73, 1) 0%, rgba(217, 216, 217, 1) 100%);
`;

export const CardFusionWrapper = styled.div<{ fl: boolean }>`
  border: 1px solid ${({ fl }) => (fl ? '#BCBC35' : 'transparent')};
  padding: 1px 1px 1px 33px;
  padding: 0 0 0 33px;
  display: flex;
  flex-flow: row nowrap;
`;

export const CardTypeWrapper = styled.div<{ f: number, r: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 31px;
  padding: 2px;
  ${({ f, r }) => {
    switch (r) {
      case 6:
      case 5:
        switch (f) {
          case 6: // Progenitor
          case 4: // Xeno
            return 'background: linear-gradient(to top, rgba(120,44,14,1) 0%,rgba(241,105,14,1) 100%);';
          case 5: // Righteous
            return 'background: linear-gradient(to top, rgba(14,150,120,1) 0%,rgba(0,255,204,1) 50%,rgba(60,180,150,1) 100%);';
          case 3: // Bloodthirsty
            return 'background: linear-gradient(to top, rgba(135,15,15,1) 0%,rgba(241,15,15,1) 50%,rgba(150,15,15,1) 100%);';
          case 2: // Raider
            return 'background: linear-gradient(to top, rgba(105,59,14,1) 0%,rgba(150,90,29,1) 50%,rgba(150,90,29,1) 100%);';
          case 1: // Imperial
          default:
            return 'background: linear-gradient(to top, rgba(14,90,135,1) 0%,rgba(74,195,226,1) 50%,rgba(0,134,165,1) 100%);';
        }
      case 4: // Legendary
        return 'background: linear-gradient(to top, rgba(241,29,74,1) 0%,rgba(241,226,226,1) 100%);';
      case 3: // Epic
        return 'background: #F1E278';
      case 2: // Rare
        return 'background: #E9E9E9';
      case 1: // Common
      default:
        return 'background: #7F6354';
    }
  }};
`;

export const StyledCardTypeIcon = styled(TypeIcon)`
  height: 100%;
  width: 100%;
`;

export const CardTypeIconWrapper = styled.div<{ r: number }>`
  height: 27px;
  width: 30px;
  line-height: 27px;
  text-align: center;
  ${({ r }) => {
    switch (r) {
      case 6:
      case 5:
        return 'background: linear-gradient(to bottom,  #e2e2e2 0%,#f1f1f1 19%,#f1f1f1 42%,#969595 100%);';
      case 4: // Legendary
        return 'background: linear-gradient(to bottom, rgba(241,180,195,1) 0%,rgba(135,29,44,1) 100%);';
      case 3: // Epic
        return 'background: linear-gradient(to top, rgba(180,135,59,1) 0%,rgba(195,165,74,1) 50%,rgba(226,211,105,1) 50%,rgba(211,180,90,1) 100%);';
      case 2: // Rare
        return 'background: linear-gradient(to top, rgba(135,135,135,1) 0%,rgba(211,211,211,1) 50%,rgba(165,165,165,1) 50%,rgba(180,180,180,1) 100%);';
      case 1: // Common
      default:
        return 'background: #E2C3A5';
    }
  }};
`;

export const CardNameAndLevelBock = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

export const CardNameWrapper = styled.div<{ f: number }>`
  box-shadow: inset 0 0 3px #000000;
  position: relative;
  /* height: 18px; */
  border-bottom: 2px solid
    ${({ f }) => {
      switch (f) {
        case 6:
          return '#A59696';
        case 5:
          return '#0EF1C3';
        case 4:
          return '#F14A0E';
        case 3:
          return '#E80000';
        case 2:
          return '#783B0E';
        case 1:
        default:
          return '#3B7887';
      }
    }};
  background: ${({ f }) => {
    switch (f) {
      case 6:
        return 'linear-gradient(to right, rgba(135,120,105,0) 0%, rgba(135,120,105,1) 100%)';
      case 5:
        return 'linear-gradient(to right, rgba(74,74,74,0) 0%,rgba(74,74,74,1) 100%)';
      case 4:
        return 'linear-gradient(to right, rgba(90,90,90,0) 0%,rgba(90,90,90,1) 100%)';
      case 3:
        return 'linear-gradient(to right, rgba(162,43,14,0) 0%,rgba(162,43,14,1) 100%)';
      case 2:
        return 'linear-gradient(to right, rgba(150,90,29,0) 0%,rgba(150,90,29,1) 100%)';
      case 1:
      default:
        return 'linear-gradient(to right, rgba(6,126,172,0) 0%,rgba(6,126,172,1) 100%)';
    }
  }};
`;
// , url('http://tyrant.webdever.ru/images/cards/${({ b, p }) => (b ? `${b}/` : '') + p}') no-repeat center 30%;
//     background-size: cover

export const CardName = styled.div`
  position: relative;
  z-index: 10;
  font-size: 12px;
  font-weight: bold;
  text-shadow: 1px 1px 0 #000000;
  overflow: hidden;
  line-height: 18px;
  line-height: 27px;
  padding-left: 3px;
  white-space: nowrap;
  font-family: Arial, sans-serif;
`;

export const CardFusionLevel = styled.div<{ fl: FusionLevel }>`
  height: 18px;
  height: 29px;
  position: absolute;
  z-index: 5;
  right: 5px;
  right: 9px;
  top: -1px;
  top: 0px;
  transform: skewX(30deg);
  ${({ fl }) => {
    if (fl === 2) {
      return `
        width: 21px;
        background: linear-gradient(to right,  rgba(195,195,14,1) 0%,rgba(195,195,14,1) 19%,rgba(135,135,14,1) 19%,rgba(135,135,14,1) 43%,rgba(241,240,14,1) 43%,rgba(241,240,14,1) 81%,rgba(195,195,14,1) 81%,rgba(195,195,14,1) 100%);
      `;
    }

    if (fl === 1) {
      return `
        width: 19px;
        background: linear-gradient(to right,  rgba(195,195,14,1) 0%,rgba(195,195,14,1) 21%,rgba(135,135,14,1) 21%,rgba(135,135,14,1) 79%,rgba(195,195,14,1) 79%,rgba(195,195,14,1) 100%);
      `;
    }

    return '';
  }}
`;

export const CardLevels = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const CardLevel = styled.div<{ on: boolean, fon: boolean }>`
  flex-grow: 1;
  display: inline-block;
  height: 8px;
  margin-left: 1px;
  box-shadow: inset 0 0 3px #000000;
  background: ${({ on, fon }) => {
    if (on) {
      if (fon) {
        return 'background: linear-gradient(to bottom,  #477419 0%,#74a72a 20%,#efff9c 50%,#6cd51d 80%,#1d5b01 100%);';
      }

      return 'background: linear-gradient(to bottom,  #071b75 0%,#5690ff 20%,#b6fdff 50%,#6dcdff 80%,#041c75 100%);';
    }

    return '#413819';
  }};
  border-radius: 2px;
`;

export const CardParamStyle = css`
  flex-shrink: 0;
  box-shadow: inset 0 0 5px #000000;
  color: #fff;
  text-align: center;
  font-size: 19px;
  font-family: Optimus, Arial, sans-serif;
  height: 27px;
  height: 29px;
  width: 27px;
  line-height: 29px;
  margin-left: 1px;
`;

export const CardAttack = styled.div<{ a: number }>`
  ${CardParamStyle}
  ${({ a }) => (a >= 100 ? 'font-size: 15px;' : '')}
  background: linear-gradient(to bottom, #f1960e 0%, #870e0e 100%);
`;

export const CardHealth = styled.div<{ h: number }>`
  ${CardParamStyle}
  ${({ h }) => (h >= 100 ? 'font-size: 15px;' : '')}
  background: linear-gradient(to bottom, #1db4c3 0%, #0e5a78 100%);
`;

export const CardDelay = styled.div`
  ${CardParamStyle};
  background: linear-gradient(to bottom, #797979 0%, #161616 100%);
`;
