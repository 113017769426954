import AppBar from '@material-ui/core/AppBar';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CARD_HEIGHT, CARD_WIDTH } from '../../constants';
import { Card, cardFactions, CardLevel, getCardType } from '../../services/cardUtils';
import downloadFile from '../../services/dowloadFile';
import Filler from '../shared/Filler';
import { Overlay } from '../shared/Overlay';
import styles from './styles';
import TUCardCanvas from './TUCardCanvas';

const useStyles = makeStyles(styles);

function promisifyImage(image: HTMLImageElement | null) {
  if (image !== null) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      // eslint-disable-next-line no-param-reassign
      image.onload = () => {
        resolve(image);
      };

      // eslint-disable-next-line no-param-reassign
      image.onerror = (error) => {
        reject(error);
      };
    });
  }
}

interface TUCardProps {
  card: Card;
  getCardNameById: (id: number) => string | null;
  onLevelChange: (...args: any) => void;
}

function TUCard({
  card: { attack, delay, faction, fusion_level, health, id, level, levels, maxLevel, name, rarity, skills, type },
  getCardNameById = () => null,
  onLevelChange
}: TUCardProps): JSX.Element {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const framesImage = useRef<HTMLImageElement | null>(null);
  const image = useRef<HTMLImageElement | null>(null);
  const skillsImage = useRef<HTMLImageElement | null>(null);
  const imageBlob = useRef<HTMLImageElement | null>(null);
  const framesImageBlob = useRef<HTMLImageElement | null>(null);
  const skillsImageBlob = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    console.time('parse');
    if (image.current && framesImage.current && skillsImage.current) {
      Promise.all([
        promisifyImage(image.current),
        promisifyImage(framesImage.current),
        promisifyImage(skillsImage.current)
      ])
        .then(([imageResult, framesImageResult, skillsImageResult]) => {
          console.timeEnd('parse');
          if (imageResult && framesImageResult && skillsImageResult) {
            imageBlob.current = imageResult;
            framesImageBlob.current = framesImageResult;
            skillsImageBlob.current = skillsImageResult;

            setLoaded(true);
          }
        })
        .catch((error) => console.error(error));
    }
  }, []);

  useEffect(() => {
    if (canvas.current && imageBlob.current && framesImageBlob.current && skillsImageBlob.current) {
      const cardCanvas = new TUCardCanvas(
        canvas.current,
        imageBlob.current,
        framesImageBlob.current,
        skillsImageBlob.current
      );
      const cardType = getCardType(id);

      cardCanvas
        .drawUnitImage()
        .drawUnitFrame(rarity, faction)
        .drawUnitFusion(fusion_level)
        .drawUnitLevel(level, maxLevel, fusion_level)
        .drawUnitCost(delay)
        .drawUnitName(name)
        .drawUnitFaction(cardFactions[faction])
        .drawSkills(skills, false, getCardNameById);

      if (typeof cardType !== 'undefined') {
        cardCanvas
          .drawUnitType(cardType)
          .drawUnitAttack(attack, cardType)
          .drawUnitHealth(health, cardType)
      }
    }
  }, [
    attack,
    delay,
    faction,
    fusion_level,
    getCardNameById,
    health,
    id,
    level,
    loaded,
    maxLevel,
    name,
    rarity,
    skills
  ]);

  const levelDown = useCallback(() => {
    onLevelChange(levels[Math.max(1, level - 1) as unknown as CardLevel]);
  }, [level, levels, onLevelChange]);

  const levelUp = useCallback(() => {
    onLevelChange(levels[Math.min(maxLevel, level + 1) as unknown as CardLevel]);
  }, [level, levels, maxLevel, onLevelChange]);

  const saveCardImage = useCallback(() => {
    if (canvas.current !== null) {
      canvas.current.toBlob((blob) => {
        if (blob !== null) {
          downloadFile(blob, `${name}-${level}`, 'image/png');
        }
      });
    }
  }, [name, level]);

  return (
    <>
      <div className={classes.cardWrapper}>
        {!loaded && <Overlay />}
        <canvas className={classes.cardCanvas} height={CARD_HEIGHT} ref={canvas} width={CARD_WIDTH} />
      </div>
      <img alt="" hidden ref={framesImage} src="assets/cardResources.png" />
      <img alt="" hidden ref={image} src="assets/cogs.jpg" />
      <img alt="" hidden ref={skillsImage} src="assets/skills.png" />
      {/*
      <div className={classes.buttonsWrapper}>
        <IconButton disabled={level === 1} onClick={levelDown}>
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton>
          <SaveAltIcon />
        </IconButton>
        <IconButton disabled={level === maxLevel} onClick={levelUp}>
          <KeyboardArrowRight />
        </IconButton>
      </div>
      */}
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          {level > 1 ? (
            <Fab
              aria-label="Level down"
              color="secondary"
              size="small"
              variant="extended"
              onClick={levelDown}
            >
              <KeyboardArrowLeft />
              Level {level - 1}
            </Fab>
          ) : null}
          <Fab aria-label="Save image" color="secondary" className={classes.fabButton} onClick={saveCardImage}>
            <SaveAltIcon />
          </Fab>
          <Filler />
          {level < maxLevel ? (
            <Fab aria-label="Level up" color="secondary" size="small" variant="extended" onClick={levelUp}>
              <KeyboardArrowRight />
              Level {level + 1}
            </Fab>
          ) : null}
        </Toolbar>
      </AppBar>
    </>
  );
}

export default TUCard;
