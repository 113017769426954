import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';

import { getSkillLine, CardLevel, CardSkillType, CardSkillSpec, Card } from "../../services/cardUtils";
import styles from './styles';

const useStyles = makeStyles(styles);

function getSkillById(skillId: CardSkillType, skills: CardSkillSpec[]): CardSkillSpec | undefined {
  return skills.find((skill) => skill.id === skillId);
}

interface SkillTableCellProps {
  index: number;
  skillId: CardSkillType;
  skills: CardSkillSpec[];
  skillsPrev: CardSkillSpec[];
  getCardNameById: (cardId: number) => string | null
}

function SkillTableCell({ index, skillId, skills, skillsPrev, getCardNameById }: SkillTableCellProps): JSX.Element {
  const skillCurrent = getSkillById(skillId, skills);
  const skillPrevious = getSkillById(skillId, skillsPrev);
  const classes = useStyles();
  const skillLineCurrent = skillCurrent ? getSkillLine(skillCurrent, getCardNameById) : '';
  const skillLinePrevious = skillPrevious ? getSkillLine(skillPrevious, getCardNameById) : '';

  return (
    <TableCell
      className={index > 1 && skillLineCurrent !== skillLinePrevious ? classes.changedParameterCell : ''}
      key={`skill-${skillId}`}
    >
      {skillCurrent ? skillLineCurrent : '-'}
    </TableCell>
  );
}

interface TUCardStatsTableProps {
  card: Card;
  getCardNameById: (cardId: number) => string | null
}

function TUCardStatsTable({ card: { levels: levelCards }, getCardNameById }: TUCardStatsTableProps): JSX.Element  {
  const classes = useStyles();
  const skillsList = Object.keys(levelCards).reduce((skills, level) => {
    levelCards[level as unknown as CardLevel].skills.forEach((cardSkill) => {
      skills.add(cardSkill.id);
    });

    return skills;
  }, new Set<CardSkillType>());

  return (
    <div className={classes.tableWrapper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell key="level-header">Level</TableCell>
            {levelCards[1].type === 'assault' && <TableCell key="attack-header">Attack</TableCell>}
            {<TableCell key="health-header">Health</TableCell>}
            {levelCards[1].type !== 'commander' && (
              <TableCell key="delay-header">Delay</TableCell>
            )}
            {skillsList.size > 0 ? (
              <TableCell key="skills-header" colSpan={skillsList.size}>
                Skills
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(levelCards).map(Number).map((index) => {
            const currentLevelCard = levelCards[index as unknown as CardLevel];
            const previousLevelCard = index > 1 ? levelCards[(index - 1) as unknown as CardLevel] : null;

            return (
              <TableRow key={`level-${index}`}>
                <TableCell key="level">{currentLevelCard.level}</TableCell>
                {currentLevelCard.type === 'assault' ? (
                  <TableCell
                    className={
                      previousLevelCard !== null && previousLevelCard.attack !== currentLevelCard.attack
                        ? classes.changedParameterCell
                        : ''
                    }
                    key="attack"
                  >
                    {currentLevelCard.attack}
                  </TableCell>
                ) : null}
                <TableCell
                  className={
                    previousLevelCard !== null && previousLevelCard.health !== currentLevelCard.health
                      ? classes.changedParameterCell
                      : ''
                  }
                  key="health"
                >
                  {currentLevelCard.health}
                </TableCell>
                {currentLevelCard.type !== 'commander' ? (
                  <TableCell
                    className={
                      previousLevelCard !== null && previousLevelCard.delay !== currentLevelCard.delay
                        ? classes.changedParameterCell
                        : ''
                    }
                    key="delay"
                  >
                    {currentLevelCard.delay}
                  </TableCell>
                ) : null}
                {Array.from(skillsList).map(
                  (skill) =>
                    (
                      <SkillTableCell
                        index={index}
                        key={`skill-${skill}`}
                        skillId={skill}
                        skills={currentLevelCard.skills}
                        skillsPrev={(previousLevelCard !== null && previousLevelCard.skills) || []}
                        getCardNameById={getCardNameById}
                      />
                    )
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  );
}

export default TUCardStatsTable;
