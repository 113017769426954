import styled from 'styled-components';

export const TUCardName = styled.div`
  font-weight: bold;
  margin-left: -4px;
`;

export const TUCardType = styled.div`
  font-style: italic;
`;
