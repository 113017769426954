import React from 'react';

import { Card, getSkillLine } from '../../services/cardUtils';
import { TUCardName, TUCardType } from './styles';

const getBoldSummonName = (getCardNameById: (id: number) => string| null) => (id: number) =>
  `<span class="hidden">**</span><b>${getCardNameById(id)}</b><span class="hidden">**</span>`;

interface TUCardDescriptionProps {
  card: Card;
  getCardNameById: (id: number) => string | null ;
}

const TUCardDescription = ({ card, getCardNameById }: TUCardDescriptionProps): JSX.Element => (
  <>
    <TUCardName>
      &nbsp;
      <span className="hidden">**</span>
      {card.name}
      <span className="hidden">**</span>
    </TUCardName>
    <TUCardType>
      <span className="hidden">_</span>
      {card.getCardFactionLine()}
      <span className="hidden">_</span>
    </TUCardType>
    <div>{card.getCardParamsLine()}</div>
    {card.skills.map((skill) => (
      <div key={skill.id}>{getSkillLine(skill, getCardNameById)}</div>
    ))}
  </>
);

export default TUCardDescription;
