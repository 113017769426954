import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React, { useCallback, useRef, useState } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import Message from '../shared/Message';
import { CARD_SECTIONS } from '../../constants';
import styles from './styles';

const useStyles = makeStyles(styles);

interface UploadXMLFormProps<T extends Error = any> {
  error: null | T;
  onInputFile: (file: Blob) => void;
  onInputRawXML: (rawXML: string) => void;
  onInputSection: (sectionNumber: number, useDevServer: boolean) => void;
}

export function UploadXMLForm({ error, onInputFile, onInputRawXML, onInputSection }: UploadXMLFormProps): JSX.Element {
  const classes = useStyles();
  const [isPasteXMLDialogOpen, setPasteXMLDialogOpen] = useState<boolean>(false);
  const [isChooseSectionDialogOpen, setChooseSectionDialogOpen] = useState<boolean>(false);
  const [XMLSection, setXMLSection] = useState<number | ''>('');
  const [devXMLIsChecked, setDevXMLIsChecked] = React.useState(false);
  const XMLInputRef = useRef<HTMLInputElement>(null);

  const openPasteXMLDialog = useCallback(() => {
    setPasteXMLDialogOpen(true);
  }, []);

  const closePasteXMLDialog = useCallback(() => {
    setPasteXMLDialogOpen(false);
  }, []);

  const openChooseSectionDialog = useCallback(() => {
    setChooseSectionDialogOpen(true);
  }, []);

  const closeChooseSectionDialog = useCallback(() => {
    setChooseSectionDialogOpen(false);
  }, []);

  const handleInputFile = useCallback((e) => {
    onInputFile(e.target.files[0]);
  }, [onInputFile]);

  const handleDevXMLCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDevXMLIsChecked(event.target.checked);
  };

  const submitPastedXML = useCallback(() => {
    const rawXML = XMLInputRef.current?.value;

    if (typeof rawXML !== 'undefined') {
      setPasteXMLDialogOpen(false);
      onInputRawXML(rawXML);
    }
  }, [onInputRawXML]);

  const handleChosenXMLSection = useCallback((e) => {
    setXMLSection(e.target.value);
  }, []);

  const submitChosenXML = useCallback(() => {
    setChooseSectionDialogOpen(false);
    XMLSection !== '' && onInputSection(XMLSection, devXMLIsChecked);
  }, [XMLSection, devXMLIsChecked, onInputSection]);

  return (
    <Container component="main" className={classes.container} maxWidth="xs">
      <div className={classes.paper}>
        <div className={classes.inner}>
          {error ? <Message type="error">{error.message}</Message> : null}
          <p>Start with providing xml with units</p>
          <label htmlFor="raised-button-file">
            <input id="raised-button-file" type="file" hidden accept=".xml,.txt" onInput={handleInputFile} />
            <Button color="primary" component="span" variant="contained">
              Upload XML file
            </Button>
          </label>
          <div className={classes.orDivider}>OR</div>
          <Button color="primary" variant="contained" onClick={openPasteXMLDialog}>
            Paste raw XML
          </Button>
          <div className={classes.orDivider}>OR</div>
          <Button color="primary" variant="contained" onClick={openChooseSectionDialog}>
            Choose XML section
          </Button>
        </div>
      </div>

      <Dialog aria-labelledby="form-dialog-title" fullWidth open={isPasteXMLDialogOpen} onClose={closePasteXMLDialog}>
        <DialogTitle id="form-dialog-title">Paste raw XML</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter unit(s) xml</DialogContentText>
          <TextField
            autoFocus
            fullWidth
            inputRef={XMLInputRef}
            id="xml"
            label="XML"
            margin="dense"
            multiline
            rows={10}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closePasteXMLDialog}>
            Cancel
          </Button>
          <Button color="primary" onClick={submitPastedXML}>
            Load
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isChooseSectionDialogOpen} onClose={closeChooseSectionDialog}>
        <DialogTitle>Choose XML section</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.xmlSelectField}>
              <InputLabel id="xml-section-label">XML section</InputLabel>
              <Select
                labelId="xml-section-label"
                id="xml-section"
                value={XMLSection}
                onChange={handleChosenXMLSection}
              >
                <MenuItem key={0} value="" />
                {Array.from({ length: CARD_SECTIONS }, (_, section) => (
                  <MenuItem key={section + 1} value={section + 1}>{`Section ${section + 1}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={devXMLIsChecked}
                  onChange={handleDevXMLCheckChange}
                  name="dev-xml"
                  color="primary"
                />
              }
              label="Use dev server"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeChooseSectionDialog}>
            Cancel
          </Button>
          <Button color="primary" disabled={XMLSection === ''} onClick={submitChosenXML}>
            Load
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
