import createStyles from '@material-ui/core/styles/createStyles';

export default () => createStyles({
  wrapper: {
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    width: '100%',
    zIndex: 1
  }
});
