import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React from 'react';

import CardsListDrawer from '../CardsListDrawer';
import styles from './styles';
import { Card } from '../../services/cardUtils';

const useStyles = makeStyles(styles);

interface SidebarProps {
  cards: Card[];
  isDrawerOpen: boolean;
  onCardSelect: (card: Card, maxed: boolean) => void;
  onDrawerToggle: () => void;
  onResetCards: React.PointerEventHandler<HTMLButtonElement>;
}

function Sidebar({ cards, isDrawerOpen, onCardSelect, onDrawerToggle, onResetCards }: SidebarProps): JSX.Element {
  const classes = useStyles();
  const DrawerInner = <CardsListDrawer cards={cards} onCardSelect={onCardSelect} />;

  return (
    <>
      <Hidden smUp>
        <Drawer
          anchor="right"
          classes={{ paper: classes.drawer }}
          open={isDrawerOpen}
          ModalProps={{
            keepMounted: true
          }}
          variant="temporary"
          onClose={onDrawerToggle}
        >
          {DrawerInner}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <AppBar elevation={0} position="relative">
          <Toolbar>
            <IconButton aria-label="Back" color="inherit" edge="start" onClick={onResetCards}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          classes={{
            paper: classes.drawer
          }}
          open
          variant="permanent"
        >
          {DrawerInner}
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
