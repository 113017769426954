import styled from 'styled-components';

const getBaseIconPosition = (iconNumber: number, iconsWidth: number, originalWidth: number) => {
  return `${((iconNumber - 1) * originalWidth * 100) / (iconsWidth - originalWidth)}% 0`;
};

export interface BaseIconProps {
  baseHeight: number;
  defaultHeight: number;
  iconNumber: number;
  iconsImage: string;
  iconsWidth: number;
  originalHeight: number;
  originalWidth: number;
}

const BaseIcon = styled.i<BaseIconProps>`
  display: inline-block;
  width: ${({ baseHeight, originalWidth, originalHeight }) => (baseHeight * originalWidth) / originalHeight}px;
  height: ${({ baseHeight }) => baseHeight}px;
  vertical-align: middle;
  background-image: url(${({ iconsImage }) => iconsImage});
  background-repeat: no-repeat;
  background-position: ${({ iconNumber, iconsWidth, originalWidth }) =>
    getBaseIconPosition(iconNumber, iconsWidth, originalWidth)};
  background-size: ${({ iconsWidth, originalWidth }) => (iconsWidth * 100) / originalWidth}% 100%;
`;

export default BaseIcon;
