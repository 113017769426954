import createStyles from '@material-ui/core/styles/createStyles';

export default () => createStyles({
  changedParameterCell: {
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  tableWrapper: {
    maxWidth: '100%',
    overflowX: 'scroll',
    '& table td': {
      whiteSpace: 'nowrap'
    }
  }
});
