import { Theme, createStyles } from '@material-ui/core/styles';

import { DRAWER_WIDTH } from '../../constants';

export default ({ breakpoints, spacing }: Theme) =>
  createStyles({
    drawer: {
      height: `100vh`,
      padding: spacing(0, 1),
      width: DRAWER_WIDTH,
      [breakpoints.up('sm')]: {
        position: 'relative',
        height: `calc(100vh - ${spacing(8)}px)`
      }
    }
  });
