import { makeStyles } from '@material-ui/core';
import React from 'react';

import styles from './styles';

const useStyles = makeStyles(styles);

interface MessageProps {
  children: React.ReactNode,
  type: string
}

function Message({ children, type = 'default' }: MessageProps): JSX.Element {
  const classes = useStyles();

  return (
    <div
      className={`${classes.messageStyle}
        ${
          // eslint-disable-next-line no-nested-ternary
          type === 'error'
            ? classes.errorMessageStyle
            : type === 'info'
            ? classes.infoMessageStyle
            : classes.defaultMessageStyle
        }`}
    >
      {children}
    </div>
  );
}

export default Message;
