import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import React, { ReactEventHandler } from 'react';

import Filler from '../shared/Filler';
import styles from './styles';
import { Card } from '../../services/cardUtils';

interface HeaderProps {
  selectedCard: Card | null;
  onDrawerToggle: () => void;
  onResetCards: ReactEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles(styles);

function Header({ selectedCard, onDrawerToggle, onResetCards }: HeaderProps): JSX.Element {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton
          aria-label="Back"
          className={classes.navIconHide}
          color="inherit"
          edge="start"
          onClick={onResetCards}
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="h6">{selectedCard !== null ? selectedCard.name : null}</Typography>
        <Filler />
        <IconButton
          aria-label="Open drawer"
          className={classes.navIconHide}
          color="inherit"
          edge="end"
          onClick={onDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
