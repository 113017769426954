export const cardConfig = {
  unitType: {
    '1': {
      id: '1',
      name: 'Imperial'
    },
    '2': {
      id: '2',
      name: 'Raider'
    },
    '3': {
      id: '3',
      name: 'Bloodthirsty'
    },
    '4': {
      id: '4',
      name: 'Xeno'
    },
    '5': {
      id: '5',
      name: 'Righteous'
    },
    '6': {
      id: '6',
      name: 'Progenitor'
    }
  },
  unitRarity: {
    '1': {
      id: '1',
      name: 'Common'
    },
    '2': {
      id: '2',
      name: 'Rare'
    },
    '3': {
      id: '3',
      name: 'Epic'
    },
    '4': {
      id: '4',
      name: 'Legendary'
    },
    '5': {
      id: '5',
      name: 'Vindicator'
    },
    '6': {
      id: '6',
      name: 'Mythic'
    }
  },
  skillType: {
    wall: {
      id: 'wall',
      name: 'Wall',
      x: 0,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 0,
        y: 0,
        width: 16,
        height: 16
      },
      order: 3000
    },
    armor: {
      id: ['armor', 'armored'],
      name: 'Armor',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 16,
        y: 0,
        width: 16,
        height: 16
      },
      order: 4000
    },
    armored: {
      id: ['armor', 'armored'],
      name: 'Armor',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 16,
        y: 0,
        width: 16,
        height: 16
      },
      order: 4000
    },
    counter: {
      id: 'counter',
      name: 'Counter',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 32,
        y: 0,
        width: 16,
        height: 16
      },
      order: 6000
    },
    heal: {
      id: 'heal',
      name: 'Heal',
      x: 1,
      y: 1,
      all: '1',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 48,
        y: 0,
        width: 16,
        height: 16
      },
      order: 105000
    },
    rally: {
      id: 'rally',
      name: 'Rally',
      x: 1,
      y: 1,
      all: '1',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 64,
        y: 0,
        width: 16,
        height: 16
      },
      order: 107000
    },
    strike: {
      id: 'strike',
      name: 'Strike',
      x: 1,
      y: 0,
      all: '1',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 80,
        y: 0,
        width: 16,
        height: 16
      },
      order: 109000
    },
    weaken: {
      id: 'weaken',
      name: 'Weaken',
      x: 1,
      y: 0,
      all: '1',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 96,
        y: 0,
        width: 16,
        height: 16
      },
      order: 111000
    },
    siege: {
      id: 'siege',
      name: 'Siege',
      x: 1,
      y: 0,
      all: '1',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 112,
        y: 0,
        width: 16,
        height: 16
      },
      order: 112000
    },
    pierce: {
      id: 'pierce',
      name: 'Pierce',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 0,
        y: 16,
        width: 16,
        height: 16
      },
      order: 152000
    },
    poison: {
      id: 'poison',
      name: 'Poison',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 16,
        y: 16,
        width: 16,
        height: 16
      },
      order: 202000
    },
    leech: {
      id: 'leech',
      name: 'Leech',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 32,
        y: 16,
        width: 16,
        height: 16
      },
      order: 203000
    },
    evade: {
      id: 'evade',
      name: 'Evade',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 48,
        y: 16,
        width: 16,
        height: 16
      },
      order: 1000
    },
    berserk: {
      id: 'berserk',
      name: 'Berserk',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 64,
        y: 16,
        width: 16,
        height: 16
      },
      order: 204000
    },
    enfeeble: {
      id: 'enfeeble',
      name: 'Enfeeble',
      x: 1,
      y: 0,
      all: '1',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 80,
        y: 16,
        width: 16,
        height: 16
      },
      order: 108000
    },
    protect: {
      id: 'protect',
      name: 'Protect',
      x: 1,
      y: 1,
      all: '1',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 96,
        y: 16,
        width: 16,
        height: 16
      },
      order: 106000
    },
    enhance: {
      id: 'enhance',
      name: 'Enhance',
      x: 1,
      y: 0,
      all: '1',
      c: '0',
      s: '1',
      s2: '0',
      n: '1',
      source: {
        id: 'skills0',
        x: 112,
        y: 16,
        width: 16,
        height: 16
      },
      order: 101000
    },
    jam: {
      id: 'jam',
      name: 'Jam',
      x: 0,
      y: 0,
      all: '1',
      c: '1',
      s: '0',
      s2: '0',
      n: '1',
      source: {
        id: 'skills0',
        x: 0,
        y: 32,
        width: 16,
        height: 16
      },
      order: 110000
    },
    corrosive: {
      id: 'corrosive',
      name: 'Corrosive',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 16,
        y: 32,
        width: 16,
        height: 16
      },
      order: 7000
    },
    inhibit: {
      id: 'inhibit',
      name: 'Inhibit',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 32,
        y: 32,
        width: 16,
        height: 16
      },
      order: 201000
    },
    flurry: {
      id: 'flurry',
      name: 'Flurry',
      x: 0,
      y: 0,
      all: '0',
      c: '1',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 48,
        y: 32,
        width: 16,
        height: 16
      },
      order: 251000
    },
    valor: {
      id: 'valor',
      name: 'Valor',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 64,
        y: 32,
        width: 16,
        height: 16
      },
      order: 51000
    },
    overload: {
      id: 'overload',
      name: 'Overload',
      x: 0,
      y: 1,
      all: '1',
      c: '0',
      s: '0',
      s2: '0',
      n: '1',
      source: {
        id: 'skills0',
        x: 80,
        y: 32,
        width: 16,
        height: 16
      },
      order: 103000
    },
    legion: {
      id: 'legion',
      name: 'Legion',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 96,
        y: 32,
        width: 16,
        height: 16
      },
      order: 151000
    },
    payback: {
      id: 'payback',
      name: 'Payback',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 112,
        y: 32,
        width: 16,
        height: 16
      },
      order: 2000
    },
    avenge: {
      id: 'avenge',
      name: 'Avenge',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 0,
        y: 48,
        width: 16,
        height: 16
      },
      order: 5000
    },
    refresh: {
      id: 'refresh',
      name: 'Refresh',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 16,
        y: 48,
        width: 16,
        height: 16
      },
      order: 203001
    },
    evolve: {
      id: 'evolve',
      name: 'Evolve',
      x: 0,
      y: 0,
      all: '1',
      c: '0',
      s: '1',
      s2: '1',
      n: '1',
      source: {
        id: 'skills0',
        x: 32,
        y: 48,
        width: 16,
        height: 16
      },
      order: 102000
    },
    venom: {
      id: 'venom',
      name: 'Venom',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 48,
        y: 48,
        width: 16,
        height: 16
      },
      order: 202001
    },
    mend: {
      id: 'mend',
      name: 'Mend',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 64,
        y: 48,
        width: 16,
        height: 16
      },
      order: 104000
    },
    mortar: {
      id: ['besiege', 'mortar'],
      name: 'Mortar',
      x: 1,
      y: 0,
      all: '1',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 80,
        y: 48,
        width: 16,
        height: 16
      },
      order: 112001
    },
    besiege: {
      id: ['besiege', 'mortar'],
      name: 'Mortar',
      x: 1,
      y: 0,
      all: '1',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 80,
        y: 48,
        width: 16,
        height: 16
      },
      order: 112001
    },
    swipe: {
      id: 'swipe',
      name: 'Swipe',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 96,
        y: 48,
        width: 16,
        height: 16
      },
      order: 153000
    },
    rupture: {
      id: 'rupture',
      name: 'Rupture',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 112,
        y: 48,
        width: 16,
        height: 16
      },
      order: 152001
    },
    rush: {
      id: 'rush',
      name: 'Rush',
      x: 0,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 0,
        y: 64,
        width: 16,
        height: 16
      },
      order: 103500
    },
    sunder: {
      id: 'sunder',
      name: 'Sunder',
      x: 1,
      y: 0,
      all: '1',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 16,
        y: 64,
        width: 16,
        height: 16
      },
      order: 111001
    },
    enrage: {
      id: 'enrage',
      name: 'Enrage',
      x: 1,
      y: 1,
      all: '1',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 32,
        y: 64,
        width: 16,
        height: 16
      },
      order: 103500
    },
    allegiance: {
      id: 'allegiance',
      name: 'Allegiance',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 48,
        y: 64,
        width: 16,
        height: 16
      },
      order: 103500
    },
    drain: {
      id: 'drain',
      name: 'Drain',
      x: 0,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 64,
        y: 64,
        width: 16,
        height: 16
      },
      order: 103500
    },
    stasis: {
      id: 'stasis',
      name: 'Stasis',
      x: 0,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 80,
        y: 64,
        width: 16,
        height: 16
      },
      order: 103500
    },
    revenge: {
      id: 'revenge',
      name: 'Revenge',
      x: 0,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 96,
        y: 64,
        width: 16,
        height: 16
      },
      order: 2001
    },
    mimic: {
      id: 'mimic',
      name: 'Mimic',
      x: 1,
      y: 0,
      all: '0',
      c: '1',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 112,
        y: 64,
        width: 16,
        height: 16
      },
      order: 113000
    },
    coalition: {
      id: 'coalition',
      name: 'Coalition',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 0,
        y: 80,
        width: 16,
        height: 16
      },
      order: 152000
    },
    sabotage: {
      id: 'sabotage',
      name: 'Sabotage',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 16,
        y: 80,
        width: 16,
        height: 16
      },
      order: 4800
    },
    barrier: {
      id: 'barrier',
      name: 'Barrier',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 32,
        y: 80,
        width: 16,
        height: 16
      },
      order: 55500
    },
    entrap: {
      id: 'entrap',
      name: 'Entrap',
      x: 1,
      y: 1,
      all: '1',
      c: '0',
      s: '0',
      s2: '0',
      n: '1',
      source: {
        id: 'skills0',
        x: 48,
        y: 80,
        width: 16,
        height: 16
      },
      order: 107501
    },
    subdue: {
      id: 'subdue',
      name: 'Subdue',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 64,
        y: 80,
        width: 16,
        height: 16
      },
      order: 7000
    },
    tribute: {
      id: 'tribute',
      name: 'Tribute',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      source: {
        id: 'skills0',
        x: 80,
        y: 80,
        width: 16,
        height: 16
      },
      order: 2002
    },
    summon: {
      id: 'summon',
      name: 'Summon',
      x: 0,
      y: 0,
      all: '0',
      c: '1',
      s: '0',
      s2: '0',
      n: '0',
      card_id: '1',
      source: {
        id: 'skills0',
        x: 96,
        y: 80,
        width: 16,
        height: 16
      },
      order: 51100
    },
    bravery: {
      id: 'bravery',
      name: 'Bravery',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      card_id: '0',
      source: {
        id: 'skills0',
        x: 112,
        y: 80,
        width: 16,
        height: 16
      },
      order: 51110
    },
    absorb: {
      id: 'absorb',
      name: 'Absorb',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      card_id: '0',
      source: {
        id: 'skills0',
        x: 0,
        y: 96,
        width: 16,
        height: 16
      },
      order: 51111
    },
    fortify: {
      id: 'fortify',
      name: 'Fortify',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      card_id: '0',
      source: {
        id: 'skills0',
        x: 64,
        y: 48,
        width: 16,
        height: 16
      },
      order: 51111
    },
    scavenge: {
      id: 'scavenge',
      name: 'Scavenge',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      card_id: '0',
      source: {
        id: 'skills0',
        x: 16,
        y: 96,
        width: 16,
        height: 16
      },
      order: 5010
    },
    flying: {
      id: 'flying',
      name: 'Flying',
      x: 0,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      card_id: '0',
      source: {
        id: 'skills0',
        x: 32,
        y: 96,
        width: 16,
        height: 16
      },
      order: 1
    },
    mark: {
      id: 'mark',
      name: 'Mark',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      card_id: '0',
      source: {
        id: 'skills0',
        x: 48,
        y: 96,
        width: 16,
        height: 16
      },
      order: 204002
    },
    hunt: {
      id: 'hunt',
      name: 'Hunt',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      card_id: '0',
      source: {
        id: 'skills0',
        x: 64,
        y: 96,
        width: 16,
        height: 16
      },
      order: 153000
    },
    disease: {
      id: 'disease',
      name: 'Disease',
      x: 1,
      y: 0,
      all: '0',
      c: '0',
      s: '0',
      s2: '0',
      n: '0',
      card_id: '0',
      source: {
        id: 'skills0',
        x: 80,
        y: 96,
        width: 16,
        height: 16
      },
      order: 201510
    }
  },
  style: {
    card_imperial_common: {
      name: 'card_imperial_common',
      type: 1,
      rarity: 1,
      source: {
        id: 'cardSource',
        x: 0,
        y: 0,
        width: 160,
        height: 220
      }
    },
    card_imperial_rare: {
      name: 'card_imperial_rare',
      type: 1,
      rarity: 2,
      source: {
        id: 'cardSource',
        x: 160,
        y: 0,
        width: 160,
        height: 220
      }
    },
    card_imperial_epic: {
      name: 'card_imperial_epic',
      type: 1,
      rarity: 3,
      source: {
        id: 'cardSource',
        x: 320,
        y: 0,
        width: 160,
        height: 220
      }
    },
    card_imperial_legendary: {
      name: 'card_imperial_legendary',
      type: 1,
      rarity: 4,
      source: {
        id: 'cardSource',
        x: 480,
        y: 0,
        width: 160,
        height: 220
      }
    },
    card_imperial_vindicator: {
      name: 'card_imperial_vindicator',
      type: 1,
      rarity: 5,
      source: {
        id: 'cardSource',
        x: 640,
        y: 0,
        width: 160,
        height: 220
      }
    },
    card_imperial_mythic: {
      name: 'card_imperial_mythic',
      type: 1,
      rarity: 6,
      source: {
        id: 'cardSource',
        x: 0,
        y: 880,
        width: 160,
        height: 220
      }
    },
    card_raider_common: {
      name: 'card_raider_common',
      type: 2,
      rarity: 1,
      source: {
        id: 'cardSource',
        x: 800,
        y: 0,
        width: 160,
        height: 220
      }
    },
    card_raider_rare: {
      name: 'card_raider_rare',
      type: 2,
      rarity: 2,
      source: {
        id: 'cardSource',
        x: 960,
        y: 0,
        width: 160,
        height: 220
      }
    },
    card_raider_epic: {
      name: 'card_raider_epic',
      type: 2,
      rarity: 3,
      source: {
        id: 'cardSource',
        x: 1120,
        y: 0,
        width: 160,
        height: 220
      }
    },
    card_raider_legendary: {
      name: 'card_raider_legendary',
      type: 2,
      rarity: 4,
      source: {
        id: 'cardSource',
        x: 1280,
        y: 0,
        width: 160,
        height: 220
      }
    },
    card_raider_vindicator: {
      name: 'card_raider_vindicator',
      type: 2,
      rarity: 5,
      source: {
        id: 'cardSource',
        x: 1440,
        y: 0,
        width: 160,
        height: 220
      }
    },
    card_raider_mythic: {
      name: 'card_raider_mythic',
      type: 2,
      rarity: 6,
      source: {
        id: 'cardSource',
        x: 160,
        y: 880,
        width: 160,
        height: 220
      }
    },
    card_bloodthirsty_common: {
      name: 'card_bloodthirsty_common',
      type: 3,
      rarity: 1,
      source: {
        id: 'cardSource',
        x: 0,
        y: 220,
        width: 160,
        height: 220
      }
    },
    card_bloodthirsty_rare: {
      name: 'card_bloodthirsty_rare',
      type: 3,
      rarity: 2,
      source: {
        id: 'cardSource',
        x: 160,
        y: 220,
        width: 160,
        height: 220
      }
    },
    card_bloodthirsty_epic: {
      name: 'card_bloodthirsty_epic',
      type: 3,
      rarity: 3,
      source: {
        id: 'cardSource',
        x: 320,
        y: 220,
        width: 160,
        height: 220
      }
    },
    card_bloodthirsty_legendary: {
      name: 'card_bloodthirsty_legendary',
      type: 3,
      rarity: 4,
      source: {
        id: 'cardSource',
        x: 480,
        y: 220,
        width: 160,
        height: 220
      }
    },
    card_bloodthirsty_vindicator: {
      name: 'card_bloodthirsty_vindicator',
      type: 3,
      rarity: 5,
      source: {
        id: 'cardSource',
        x: 640,
        y: 220,
        width: 160,
        height: 220
      }
    },
    card_bloodthirsty_mythic: {
      name: 'card_bloodthirsty_mythic',
      type: 3,
      rarity: 6,
      source: {
        id: 'cardSource',
        x: 320,
        y: 880,
        width: 160,
        height: 220
      }
    },
    card_xeno_common: {
      name: 'card_xeno_common',
      type: 4,
      rarity: 1,
      source: {
        id: 'cardSource',
        x: 800,
        y: 220,
        width: 160,
        height: 220
      }
    },
    card_xeno_rare: {
      name: 'card_xeno_rare',
      type: 4,
      rarity: 2,
      source: {
        id: 'cardSource',
        x: 960,
        y: 220,
        width: 160,
        height: 220
      }
    },
    card_xeno_epic: {
      name: 'card_xeno_epic',
      type: 4,
      rarity: 3,
      source: {
        id: 'cardSource',
        x: 1120,
        y: 220,
        width: 160,
        height: 220
      }
    },
    card_xeno_legendary: {
      name: 'card_xeno_legendary',
      type: 4,
      rarity: 4,
      source: {
        id: 'cardSource',
        x: 1280,
        y: 220,
        width: 160,
        height: 220
      }
    },
    card_xeno_vindicator: {
      name: 'card_xeno_vindicator',
      type: 4,
      rarity: 5,
      source: {
        id: 'cardSource',
        x: 1440,
        y: 220,
        width: 160,
        height: 220
      }
    },
    card_xeno_mythic: {
      name: 'card_xeno_mythic',
      type: 4,
      rarity: 6,
      source: {
        id: 'cardSource',
        x: 480,
        y: 880,
        width: 160,
        height: 220
      }
    },
    card_righteous_common: {
      name: 'card_righteous_common',
      type: 5,
      rarity: 1,
      source: {
        id: 'cardSource',
        x: 0,
        y: 440,
        width: 160,
        height: 220
      }
    },
    card_righteous_rare: {
      name: 'card_righteous_rare',
      type: 5,
      rarity: 2,
      source: {
        id: 'cardSource',
        x: 160,
        y: 440,
        width: 160,
        height: 220
      }
    },
    card_righteous_epic: {
      name: 'card_righteous_epic',
      type: 5,
      rarity: 3,
      source: {
        id: 'cardSource',
        x: 320,
        y: 440,
        width: 160,
        height: 220
      }
    },
    card_righteous_legendary: {
      name: 'card_righteous_legendary',
      type: 5,
      rarity: 4,
      source: {
        id: 'cardSource',
        x: 480,
        y: 440,
        width: 160,
        height: 220
      }
    },
    card_righteous_vindicator: {
      name: 'card_righteous_vindicator',
      type: 5,
      rarity: 5,
      source: {
        id: 'cardSource',
        x: 640,
        y: 440,
        width: 160,
        height: 220
      }
    },
    card_righteous_mythic: {
      name: 'card_righteous_mythic',
      type: 5,
      rarity: 6,
      source: {
        id: 'cardSource',
        x: 640,
        y: 880,
        width: 160,
        height: 220
      }
    },
    card_progenitor_common: {
      name: 'card_progenitor_common',
      type: 6,
      rarity: 1,
      source: {
        id: 'cardSource',
        x: 800,
        y: 440,
        width: 160,
        height: 220
      }
    },
    card_progenitor_rare: {
      name: 'card_progenitor_rare',
      type: 6,
      rarity: 2,
      source: {
        id: 'cardSource',
        x: 960,
        y: 440,
        width: 160,
        height: 220
      }
    },
    card_progenitor_epic: {
      name: 'card_progenitor_epic',
      type: 6,
      rarity: 3,
      source: {
        id: 'cardSource',
        x: 1120,
        y: 440,
        width: 160,
        height: 220
      }
    },
    card_progenitor_legendary: {
      name: 'card_progenitor_legendary',
      type: 6,
      rarity: 4,
      source: {
        id: 'cardSource',
        x: 1280,
        y: 440,
        width: 160,
        height: 220
      }
    },
    card_progenitor_vindicator: {
      name: 'card_progenitor_vindicator',
      type: 6,
      rarity: 5,
      source: {
        id: 'cardSource',
        x: 1440,
        y: 440,
        width: 160,
        height: 220
      }
    },
    card_progenitor_mythic: {
      name: 'card_progenitor_mythic',
      type: 6,
      rarity: 6,
      source: {
        id: 'cardSource',
        x: 800,
        y: 880,
        width: 160,
        height: 220
      }
    }
  },
  frame: {
    fused_card_overlay2: {
      name: 'fused_card_overlay2',
      fusion_level: '1',
      source: {
        id: 'cardSource',
        x: 1600,
        y: 220,
        width: 160,
        height: 220
      }
    },
    fused_card_overlay3: {
      name: 'fused_card_overlay3',
      fusion_level: '2',
      source: {
        id: 'cardSource',
        x: 1600,
        y: 440,
        width: 160,
        height: 220
      }
    }
  },
  icon: {
    cost_container: {
      x: 1600,
      y: 0,
      width: 32,
      height: 32
    },
    icon_commander: {
      x: 1600,
      y: 32,
      width: 24,
      height: 24
    },
    icon_assault: {
      x: 1624,
      y: 32,
      width: 24,
      height: 24
    },
    icon_structure: {
      x: 1648,
      y: 32,
      width: 24,
      height: 24
    },
    icon_action: {
      x: 1672,
      y: 32,
      width: 24,
      height: 24
    },
    icon_dominion: {
      x: 1696,
      y: 32,
      width: 24,
      height: 24
    },
    icon_unfused_empty: {
      x: 1632,
      y: 0,
      width: 11,
      height: 11
    },
    icon_unfused_full: {
      x: 1632,
      y: 11,
      width: 11,
      height: 11
    },
    icon_fused_empty: {
      x: 1643,
      y: 0,
      width: 11,
      height: 11
    },
    icon_fused_full: {
      id: 'cardSource',
      x: 1643,
      y: 11,
      width: 11,
      height: 11
    }
  }
} as const;
