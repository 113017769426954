import { createStyles, Theme } from '@material-ui/core/styles';

export default ({ breakpoints, spacing }: Theme) =>
  createStyles({
    cardSectionWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%'
    },
    content: {
      flexGrow: 1,
      marginTop: spacing(7),
      [breakpoints.up('sm')]: {
        marginTop: spacing(8)
      },
      overflow: 'auto',
      position: 'relative'
    },
    downloadButton: {
      position: 'absolute',
      bottom: spacing(4),
      right: spacing(4)
    },
    tabInner: {
      flexGrow: 1,
      height: '100%',
      padding: spacing(8, 2, 2),
      transform: 'translate(0, 0)',
      width: '100%'
    },
    tabInnerCardView: {
      paddingBottom: spacing(11)
    },
    XMLCompare: {
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'column nowrap',
      flexGrow: 1,
      gap: spacing(2),
      height: '100%'
    },
    XMLCompareHalf: {
      display: 'flex',
      flexBasis: '50%',
      flexFlow: 'column nowrap'
    },
    XMLWrapper: {
      borderColor: '#cccccc',
      borderRadius: 5,
      flexGrow: 1,
      fontFamily: 'monospace',
      padding: spacing(1),
      resize: 'none',
      whiteSpace: 'pre',
      width: '100%'
    }
  });
