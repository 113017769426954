import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import CardLine from './CardLine';
import { Card } from "../../services/cardUtils";

const ListItemWrapper = styled.div`
  margin: 5px 0; 
`;

interface CardsListItemProps {
  card: Card;
  id: number;
  name: string;
  selected: boolean;
  onListItemClick: (card: Card, selected: boolean) => void;
}

const CardsListItem = ({ card, id, name, selected, onListItemClick }: CardsListItemProps): JSX.Element => {
  const handleListItemClick = useCallback(() => {
    onListItemClick(card, true);
  }, [card, onListItemClick]);

  return (
    <ListItemWrapper /* selected={selected} */>
      <CardLine
        assetBundle={card.asset_bundle}
        attack={card.attack}
        cost={card.delay}
        faction={card.faction}
        fusionLevel={card.fusion_level}
        health={card.health}
        id={card.id}
        level={card.level}
        levels={card.levels}
        name={card.name}
        picture={card.picture}
        rarity={card.rarity}
        type={card.type}
        onCardLineClick={handleListItemClick}
      />
      {/*
      <ListItem button selected={selected} onClick={handleListItemClick}>
        <ListItemText primary={name} />
      </ListItem>
      */}
    </ListItemWrapper>
  );
};

CardsListItem.defaultProps = {
  selected: false
};

CardsListItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onListItemClick: PropTypes.func.isRequired
};

export default CardsListItem;
