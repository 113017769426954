import AppBar from '@material-ui/core/AppBar';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { convertCardToXML } from '../../services/cardParser';
import ErrorBoundary from '../shared/ErrorBoundary';
import TUCard from '../TUCard';
import TUCardDescription from '../TUCardDescription';
import TUCardStatsTable from '../TUCardStatsTable';
import styles from './styles';
import { Card } from '../../services/cardUtils';

const useStyles = makeStyles(styles);

interface MainProps {
  card: Card | null;
  getCardNameById: (id: number) => string | null;
  onLevelChange: (..._: any[]) => void;
  onSaveXML: () => void;
}

function Main({ card, getCardNameById, onLevelChange, onSaveXML }: MainProps): JSX.Element {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [currentXMLTab, setCurrentXMLTab] = useState<'raw-xml' | 'exported-xml'>('raw-xml');
  const scrollPosition = useRef<number>(0);
  const textareaRawXMLRef = useRef<HTMLTextAreaElement | null>(null);
  const textareaExportedXMLRef = useRef<HTMLTextAreaElement | null>(null);

  const handleTabChange = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const handleXMLCompareChange = useCallback((event, newValue) => {
    newValue && setCurrentXMLTab(newValue);
  }, []);

  const handleRawXMLScroll = useCallback((event) => {
    scrollPosition.current = event.target.scrollTop;
  }, []);

  const handleExportedXMLScroll = useCallback((event) => {
    scrollPosition.current = event.target.scrollTop;
  }, []);

  useEffect(() => {
    scrollPosition.current = 0;
  }, [card?.id]);

  useEffect(() => {
    if (currentXMLTab === 'raw-xml') {
      textareaRawXMLRef.current && (textareaRawXMLRef.current.scrollTop = scrollPosition.current);
    } else if (currentXMLTab === 'exported-xml') {
      textareaExportedXMLRef.current && (textareaExportedXMLRef.current.scrollTop = scrollPosition.current);
    }
  }, [currentXMLTab, card?.id]);

  return (
    <main className={classes.content}>
      <AppBar color="default" position="absolute">
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={currentTab}
          variant="fullWidth"
          onChange={handleTabChange}
        >
          <Tab label="Image" />
          <Tab label="Table" />
          <Tab label="Text" />
          <Tab label="XML" />
        </Tabs>
      </AppBar>
      <div className={`${classes.tabInner} ${classes.tabInnerCardView}`} hidden={currentTab !== 0}>
        {card !== null ? (
          <div className={classes.cardSectionWrapper}>
            <ErrorBoundary>
              <TUCard card={card} getCardNameById={getCardNameById} onLevelChange={onLevelChange} />
            </ErrorBoundary>
          </div>
        ) : null}
      </div>
      <div className={classes.tabInner} hidden={currentTab !== 1}>
        {card ? <TUCardStatsTable card={card} getCardNameById={getCardNameById} /> : null}
      </div>
      <div className={classes.tabInner} hidden={currentTab !== 2}>
        {card ? <TUCardDescription card={card} getCardNameById={getCardNameById} /> : null}
      </div>
      <div className={classes.tabInner} hidden={currentTab !== 3}>
        <div className={classes.XMLCompare}>
          <ToggleButtonGroup orientation="horizontal" value={currentXMLTab} exclusive onChange={handleXMLCompareChange}>
            <ToggleButton value="raw-xml" aria-label="raw-xml">
              Original XML
            </ToggleButton>
            <ToggleButton value="exported-xml" aria-label="exported-xml">
              Exported XML
            </ToggleButton>
          </ToggleButtonGroup>
          {card ? (
            <>
              <textarea ref={textareaRawXMLRef} className={classes.XMLWrapper} readOnly value={card.rawXML} hidden={currentXMLTab !== 'raw-xml'} onScroll={handleRawXMLScroll} />
              <textarea ref={textareaExportedXMLRef} className={classes.XMLWrapper} readOnly value={convertCardToXML(card.levels['1'])} hidden={currentXMLTab !== 'exported-xml'} onScroll={handleExportedXMLScroll} />
            </>
          ) : null}
        </div>
        <Fab aria-label="Download XML" className={classes.downloadButton} color="primary">
          <SaveAltIcon onClick={onSaveXML} />
        </Fab>
      </div>
    </main>
  );
}

export default Main;
