import { DRAWER_WIDTH } from '../../constants';
import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  appBar: {
    position: 'absolute',
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`
    }
  },
  navIconHide: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
});
