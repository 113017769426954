import { useEffect, useState } from 'react';

interface useFileReaderProps {
  onSuccess: (xml: string) => void;
}

type useFileReaderReturn = [
  {
    fileLoadingError: DOMException | null;
    isFileLoading: boolean;
    progress: number;
  },
  (file: Blob) => void
];

const useFileReader = ({ onSuccess }: useFileReaderProps): useFileReaderReturn => {
  const [file, setFile] = useState<Blob | null>(null);
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [fileLoadingError, setFileLoadingError] = useState<DOMException | null>(null);

  useEffect(() => {
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onloadstart = () => {
      setIsFileLoading(true);
    };

    reader.onloadend = () => {
      setIsFileLoading(false);
    };

    reader.onload = () => {
      onSuccess(reader.result as string);
    };

    reader.onprogress = (e) => {
      setProgress(e.loaded / e.total);
    };

    reader.onerror = () => {
      setFileLoadingError(reader.error);
    };

    try {
      reader.readAsText(file);
    } catch (e) {
      setFileLoadingError(e);
    }
  }, [file, onSuccess]);

  return [
    {
      fileLoadingError,
      isFileLoading,
      progress
    },
    setFile
  ];
};

export default useFileReader;
