import { Theme, createStyles } from '@material-ui/core/styles';

export default ({ spacing }: Theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'stretch'
    },
    inner: {
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    orDivider: {
      padding: spacing(1, 0)
    },
    paper: {
      alignItems: 'center',
      border: '1px solid #cccccc',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'column'
    },
    xmlSelectField: {
      minWidth: '180px'
    }
  });
