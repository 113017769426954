import React from 'react';

import typeIconsImage from './assets/type-icons.png';
import BaseIcon from './BaseIcon';
import { BaseIconProps } from './BaseIcon/BaseIcon';

const TYPE_ICON_DEFAULT_HEIGHT = 21;
const TYPE_ICON_ORIGINAL_HEIGHT = 21;
const TYPE_ICON_ORIGINAL_WIDTH = 24;
const TYPE_ICONS_WIDTH = 120;

interface TypeIconProps {
  base?: number;
  type?: number;
}

const TypeIcon = ({ base = TYPE_ICON_DEFAULT_HEIGHT, type = 0 }: TypeIconProps): JSX.Element => (
  <BaseIcon
    baseHeight={base}
    defaultHeight={TYPE_ICON_DEFAULT_HEIGHT}
    iconNumber={type}
    iconsImage={typeIconsImage}
    iconsWidth={TYPE_ICONS_WIDTH}
    originalHeight={TYPE_ICON_ORIGINAL_HEIGHT}
    originalWidth={TYPE_ICON_ORIGINAL_WIDTH}
  />
);

export default TypeIcon;
