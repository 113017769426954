import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
// import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';

// import { actionExample } from '../../redux/actions';
import { convertCardToXML } from '../../services/cardParser';
import { Card, CardLevel } from '../../services/cardUtils';
import downloadFile from '../../services/dowloadFile';
import { useCardsXMLParser } from '../../services/useCardsXMLParser';
import useFileReader from '../../services/useFileReader';
import { useXMLSectionLoader } from '../../services/useXMLSectionLoader';
import Header from '../Header';
import Main from '../Main';
import { Overlay } from '../shared/Overlay';
import Sidebar from '../Sidebar';
import { UploadXMLForm } from '../UploadXMLForm';
import styles from './styles';

const useStyles = makeStyles(styles);

export function App(): JSX.Element {
  const classes = useStyles();
  const [cards, setCards] = useState<Card[]>([]);
  const setCardsCallback = useCallback((cards: Card[]) => {
    setCards(cards);
  }, []);
  const [{ isXMLParsing, XMLParsingError }, setXMLCallback] = useCardsXMLParser({ onSuccess: setCardsCallback });
  const [{ fileLoadingError, isFileLoading }, setFile] = useFileReader({ onSuccess: setXMLCallback });
  const [{ XMLSectionLoadingError, isXMLSectionLoading }, setSection] = useXMLSectionLoader({ onSuccess: setXMLCallback });
  const [selectedCard, setSelectedCard] = useState<Card| null>(null);
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [isConfirmResetCardsDialogOpen, setConfirmResetCardsDialogOpen] = useState<boolean>(false);
  const handleCardSelect = useCallback((card: Card, maxed = false) => {
    setSelectedCard(maxed ? card.levels[`${Object.keys(card.levels).length}` as unknown as CardLevel] : card);
    setDrawerOpen(false);
  }, []);
  const getCardNameById = useCallback(
    (id: number) => {
      const foundCard = cards.find((card) => Object.values(card.levels).some((level) => level.id === id));

      if (foundCard !== undefined) {
        return foundCard.name;
      }

      return null;
    },
    [cards]
  );
  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen(!isDrawerOpen);
  }, [isDrawerOpen]);
  const handleResetCards = useCallback(() => {
    setConfirmResetCardsDialogOpen(true);
  }, []);
  const confirmResetCards = useCallback(() => {
    setCards([]);
    setXMLCallback(null);
  }, [setXMLCallback]);
  const closeConfirmResetCardsDialog = useCallback(() => {
    setConfirmResetCardsDialogOpen(false);
  }, []);
  const isLoading = isFileLoading || isXMLParsing || isXMLSectionLoading;
  const uploadFormError = fileLoadingError || XMLParsingError || XMLSectionLoadingError;
  const handleSaveXML = useCallback(() => {
    if (selectedCard !== null) {
      const data = convertCardToXML(selectedCard.levels['1']);

      downloadFile(data, `${selectedCard.name}.xml`, 'application/xml');
    }
  }, [selectedCard]);

  useEffect(() => {
    if (cards.length) {
      setSelectedCard(cards[0].levels[`${Object.keys(cards[0].levels).length}` as unknown as CardLevel]);
    }
  }, [cards]);

  return (
    <div className={classes.wrapper}>
      <CssBaseline />
      {isLoading ? <Overlay /> : null}
      {!isLoading ? (
        <>
          {cards.length > 0 ? (
            <>
              <Header selectedCard={selectedCard} onDrawerToggle={handleDrawerToggle} onResetCards={handleResetCards} />
              <Sidebar
                cards={cards}
                isDrawerOpen={isDrawerOpen}
                onCardSelect={handleCardSelect}
                onDrawerToggle={handleDrawerToggle}
                onResetCards={handleResetCards}
              />
              <Main
                card={selectedCard}
                getCardNameById={getCardNameById}
                onLevelChange={handleCardSelect}
                onSaveXML={handleSaveXML}
              />

              <Dialog aria-labelledby="form-dialog-title" fullWidth open={isConfirmResetCardsDialogOpen} onClose={closeConfirmResetCardsDialog}>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to clear previously loaded cards?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button color="primary" variant="contained" onClick={closeConfirmResetCardsDialog}>
                    Cancel
                  </Button>
                  <Button autoFocus color="primary" onClick={confirmResetCards}>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : null}
          {cards.length === 0 ? (
            <UploadXMLForm error={uploadFormError} onInputFile={setFile} onInputRawXML={setXMLCallback} onInputSection={setSection} />
          ) : null}
        </>
      ) : null}
    </div>
  );
}

// App.defaultProps = {};
//
// App.propTypes = {};

/*
const mapStateToProps = state => {
  return {
    stateOption1: state.reducerExample.stateOption1
  };
};
*/

// export default App; // connect(mapStateToProps, { actionExample })(App);
